import { useSelector } from 'react-redux';
import type { JurisdictionData } from '../selectors/interfaces';
import { useGetUserLocation } from './use-get-user-location/use-get-user-location';
import { issuerSelector } from '../selectors';

export const useGetJurisdictionData = (): JurisdictionData | undefined => {
  const { countryCode } = useGetUserLocation();
  const { jurisdictionByCountryCode } = useSelector(issuerSelector);
  const maybeJurisdictionData = jurisdictionByCountryCode?.[countryCode];

  return maybeJurisdictionData ?? undefined;
};
