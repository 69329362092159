import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Issuer } from '../constants/issuers';
import { issuerSelector } from '../selectors';
import { useGetIssuerShortname } from '../hooks/use-get-issuer-shortname';
import { useIsInitialDataLoaded } from '../components/initial-page-load-handler/initial-page-load-handler';

const ISSUER_TO_CONHOST_MAP: Readonly<{ [key in Issuer]: string | undefined }> =
  {
    [Issuer.ARAMARK]: 'daf482888aec7fc3a61980cf490942aa',
    [Issuer.COMPANY_A]: undefined,
    [Issuer.COMPANY_ABC]: undefined,
    [Issuer.DENALI]: '0032d743b36a7fea61866a91d08e481c',
    [Issuer.ZEVRA]: '994d3e9608d4a52d5e708450e79b49dc',
    [Issuer.LUCIDDX]: 'ccacc124b548c0556ff3f84a3dacdca1',
    [Issuer.OUTSET_MEDICAL]: '8ac6aef8d6fcc0079d8464ff1ee2c2a1',
    [Issuer.PAVMED]: '5a2746f132f6c5ea1dd04e326b7b3cb1',
    [Issuer.VELFINANCE]: 'a3f17adbdfb5f185c75f115fe828e5ea',
    [Issuer.HTT]: undefined,
    [Issuer.RBGLOBAL]: '8c16529533b3972162ebb78265f9756e',
    [Issuer.TESTCO]: '5166124fa0369ef15669a45cd09020b4',
    [Issuer.AMEXGBT]: 'ce8b249f3deab9c5a8f5e77d5ffdaf09',
    [Issuer.TESTCO_ENROLLMENT]: '5166124fa0369ef15669a45cd09020b4',
    [Issuer.TESTCO_ENROLLMENT_CASHLESS_ONLY]:
      '5166124fa0369ef15669a45cd09020b4',
    [Issuer.RESMED]: '86702b38a09714371bc28293e0b79f9f',
    [Issuer.SAMPLECO]: '43ae6146b0fab43c1d1732559a4e66a6',
    [Issuer.ICF]: '3898901741f78fe96740961c7fbfa090',
    [Issuer.EBAY]: '5b5acaa505d6a3711c9b54b061990f07',
    [Issuer.MKINDUSTRIES]: 'b93bb5fb319eb33809393c9b9899ad54',
    [Issuer.OMADA]: '06d3e3f3cff60f15cc7aff3bcc3fd2eb',
    [Issuer.PELOTON]: '54263eb8dfcf9ff15f0df441a7eeee34',
    [Issuer.DOLBY]: '7e22658171e290bd2e7d65c4e2cc5da0',
    [Issuer.PAYPAL]: 'e9c8329e466378eb5be9f68118851beb',
  };

/** This pulls the dotcms conhost ID from one of two places:
 *
 * 1. ISSUER_TO_CONHOST_MAP - this lets us fetch content from dotcms in parallel with get_assets.
 *    We should try to keep this map always populated with all issuers, because an issuer's site
 *    will load slower if it is not in this map.
 *
 * 2. cms_site_id from get_assets - we fall back to this, so that we can launch a new issuer without
 *    having to push new code. This is useful for e.g. quickly creating a new demo site.
 */
export const useGetConhostForIssuer = () => {
  const shortname = useGetIssuerShortname() ?? '';
  const { cms_site_id: siteIdFromBackend, getAssetsHadError } =
    useSelector(issuerSelector);
  const initialDataLoaded = useIsInitialDataLoaded();
  // Don't complain about being able to get a site ID from the backend if the backend data
  // hasn't loaded yet:
  const siteIdFromBackendShouldBePresent =
    !!shortname && initialDataLoaded && !getAssetsHadError;

  const siteIdsFromSourceCode: Readonly<Record<string, string | undefined>> =
    ISSUER_TO_CONHOST_MAP;
  const siteIdFromSource: string | undefined = siteIdsFromSourceCode[shortname];

  useEffect(() => {
    if (
      siteIdFromBackend &&
      siteIdFromSource &&
      siteIdFromBackend !== siteIdFromSource
    ) {
      console.error(
        `cms_site_id from backend (${siteIdFromBackend}) does not match site ID from source code (${siteIdFromSource}) for issuer ${shortname}`,
      );
    }
  }, [shortname, siteIdFromBackend, siteIdFromSource]);

  // The backend value is more likely to be up-to-date if it ever changes for some reason, so
  // prefer that one:
  const siteIdToUse = siteIdFromBackend ?? siteIdFromSource;

  // Error logging:
  useEffect(() => {
    if (!siteIdFromBackendShouldBePresent) {
      // Don't log an error if the backend hasn't loaded yet.
      // We don't know which error case, if any, to log yet.
      return;
    }
    // We only want to log at most 1 of these errors at once:
    if (!siteIdToUse) {
      console.error(
        `Could not look up issuer host! All displayed CMS content will be from the default issuer. The issuer was: ${
          shortname || '[a falsy value]'
        }`,
      );
      return;
    }
    if (!siteIdFromSource) {
      console.error(
        `No cms site ID found for issuer ${shortname} in ISSUER_TO_CONHOST_MAP - if this is a new client, please add it to the map`,
      );
      return;
    }
    if (!siteIdFromBackend) {
      console.error(
        `Could not find cms_site_id from the backend response for issuer ${shortname}`,
      );
    }
  }, [
    shortname,
    siteIdFromBackend,
    siteIdFromBackendShouldBePresent,
    siteIdFromSource,
    siteIdToUse,
  ]);

  return siteIdToUse || undefined;
};
