import React from 'react';
import useReminderToEnrollPopup from './hooks/use-reminder-to-enroll-popup';
import { DataElementId } from '../../../../constants/data-element-tracking-ids';
import BasicPopup from '../basic-popup';

const ReminderToEnrollPopup = () => {
  const {
    dialogData,
    dialogOpen,
    loading,
    cmsError,
    handleOnClose,
    externalEnrollmentRoute,
  } = useReminderToEnrollPopup();

  return (
    <BasicPopup
      id="reminder-to-enroll-popup"
      dataElementId={DataElementId.REMINDER_TO_ENROLL_POPUP}
      dialogData={dialogData}
      dialogOpen={dialogOpen}
      loading={loading}
      cmsError={cmsError}
      handleOnClose={handleOnClose}
      primaryButton={{
        content: dialogData?.primaryButton?.text,
        route: externalEnrollmentRoute,
        onClick: handleOnClose,
        dataElementId:
          DataElementId.REMINDER_TO_ENROLL_POPUP_REDIRECT_TO_STOCK_PANEL_BUTTON,
      }}
      secondaryButton={{
        content: dialogData?.secondaryButton?.text,
        onClick: handleOnClose,
        dataElementId: DataElementId.REMINDER_TO_ENROLL_POPUP_CLOSE_BUTTON,
      }}
    />
  );
};

export default ReminderToEnrollPopup;
