import React from 'react';
import { useTheme } from '@mui/material';
import HtmlBox from '../../../component_library/components/HtmlBox/HtmlBox';
import Dialog from '../../../component_library/components/Dialog';
import { LoadingOverlay } from '../loading-overlay/loading-overlay';
import type { IDialogData } from '../../../dotcms/dotcms-models/dotcms-dialog';
import { useGetTheme } from '../../../hooks/use-get-theme';
import type { IDialogButton } from '../../../component_library/components/Dialog/DialogButton';
import type { IPropsWithTestId } from '../../../constants/data-element-tracking-ids';

interface IProps extends IPropsWithTestId {
  id: string;
  dialogData: IDialogData | undefined;
  dialogOpen: boolean;
  loading: boolean;
  cmsError: unknown;
  handleOnClose?: () => void;
  primaryButton: IDialogButton;
  secondaryButton?: IDialogButton;
}

const BasicPopup: React.FC<IProps> = ({
  id,
  dialogData,
  dialogOpen,
  loading,
  cmsError,
  handleOnClose,
  primaryButton,
  secondaryButton,
  dataElementId,
}) => {
  const theme = useGetTheme();
  const muiTheme = useTheme();

  if (loading) {
    return <LoadingOverlay />;
  }

  if (cmsError || !dialogData) {
    return null;
  }

  return (
    <Dialog
      id={id}
      dataElementId={dataElementId}
      open={dialogOpen ?? false}
      onDialogClose={handleOnClose}
      theme={theme}
      muiTheme={muiTheme}
      centeredContent
      headlineContent={<HtmlBox html={dialogData.headline} />}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
    >
      <HtmlBox html={dialogData.content} />
    </Dialog>
  );
};

export default BasicPopup;
