import type { IBaseObjectData } from './dotcms-base-object';
import type { IGqlHelper } from './gql-helper';
import {
  convertDotcmsResponseToRecord,
  convertDotcmsString,
} from './converters-from-dotcms';
import type { IDialogData } from './dotcms-dialog';
import { DialogGqlHelper } from './dotcms-dialog';
import { buildGqlFieldsQuery } from './gql-builders';

export type IGlobalTextVariablesData = IBaseObjectData & {
  carverEdisonName?: string;
  yes?: string;
  no?: string;
  trueText?: string;
  falseText?: string;
  cashlessParticipationOn?: string;
  cashlessParticipationOff?: string;
  eligiblePayName?: string;
  percentagePostfix?: string;
  sharesPostfix?: string;
  loadingText?: string;
  allText?: string;
  notAvailableNotApplicable?: string;
  unknownText?: string;
  payFrequencyCalculatorWeekly?: string;
  payFrequencyCalculatorBiweekly?: string;
  payFrequencyCalculatorMonthly?: string;
  payFrequencyCalculatorSemimonthly?: string;
  payFrequencyCalculatorLunar?: string;
  perPurchasePeriod?: string;
  perOfferingPeriod?: string;
  currencyExchangeFxRatePlaceholder?: string;
  saveButtonText?: string;
  backButtonText?: string;
  editButtonText?: string;
  documentNameCashlessAgreement?: string;
  documentDescriptionCashlessAgreement?: string;
  documentNameEnrollmentAgreement?: string;
  documentDescriptionEnrollmentAgreement?: string;
  documentNameProspectusSupplement?: string;
  documentDescriptionProspectusSupplement?: string;
  documentNameEsppSubPlan?: string;
  documentDescriptionEsppSubPlan?: string;
  documentNamePurchaseSummary?: string;
  documentNamePayrollAuthorizationForm?: string;
  documentDescriptionPayrollAuthorizationForm?: string;
  documentNameWithdrawalConfirmation?: string;
  documentNameUsTax1099B?: string;
  documentNameUsTax1099BSupplement?: string;
  documentNameUsTax1099BUnofficial?: string;
  documentNameUsTax3922?: string;
  documentTypeNameStatement?: string;
  documentTypeNameContract?: string;
  documentTypeNameConfirmation?: string;
  documentTypeNameTaxDocument?: string;
  documentTypeNameInformation?: string;
  documentTypeNameStatementPlural?: string;
  documentTypeNameContractPlural?: string;
  documentTypeNameConfirmationPlural?: string;
  documentTypeNameTaxDocumentPlural?: string;
  documentTypeNameInformationPlural?: string;
  documentNameEmployeeStockPurchasePlan?: string;
  documentDescriptionEmployeeStockPurchasePlan?: string;
  documentNameS8Prospectus?: string;
  documentDescriptionS8Prospectus?: string;
  documentNameCombinedEsppAndProspectus?: string;
  documentDescriptionCombinedEsppAndProspectus?: string;
  documentNameSellToCoverSupplement?: string;
  documentDescriptionSellToCoverSupplement?: string;
  documentNameClearingBrokerStatement?: string;
  documentDescriptionClearingBrokerStatement?: string;
  documentNamePurchaseStatement?: string;
  documentDescriptionPurchaseStatement?: string;
  documentNameTaxSummary?: string;
  documentDescriptionTaxSummary?: string;
  documentNameClearingBrokerConfirm?: string;
  documentDescriptionClearingBrokerConfirm?: string;
  documentNameCancellationOfWithdrawal?: string;
  documentDescriptionCancellationOfWithdrawal?: string;
  documentNameInformationDocument?: string;
  documentDescriptionInformationDocument?: string;
  articleCategorySectionHeadingOverview?: string;
  articleCategorySectionHeadingMisc?: string;
  articleCategorySectionHeadingTax?: string;
  articleCategorySectionHeadingTraining?: string;
  learnMoreLinkText?: string;
  betaLabelForBetaFeature?: string;
  articleResourceTypeBasics?: string;
  articleResourceTypeVideo?: string;
  articleResourceTypeDocuments?: string;
  offeringPeriodName?: string;
  purchasePeriodName?: string;
  genericErrorPopup?: IDialogData;
  downloadPageButtonText?: string;
  downloadExcelButtonText?: string;
  downloadCsvButtonText?: string;
  showItemsCountText?: string;
  showItemsCountTextWithoutTotal?: string;
  encryptedDataDisclaimerText?: string;
};

export const GlobalTextVariablesGqlHelper: IGqlHelper<IGlobalTextVariablesData> =
  {
    contentTypeName: 'GlobalTextVariables',
    gqlFieldsQuery: `
    carverEdisonName
    yes
    no
    trueText
    falseText
    cashlessParticipationOn
    cashlessParticipationOff
    eligiblePayName
    percentagePostfix
    sharesPostfix
    loadingText
    allText
    notAvailableNotApplicable
    unknownText
    payFrequencyCalculatorWeekly
    payFrequencyCalculatorBiweekly
    payFrequencyCalculatorMonthly
    payFrequencyCalculatorSemimonthly
    payFrequencyCalculatorLunar
    perPurchasePeriod
    perOfferingPeriod
    currencyExchangeFxRatePlaceholder
    saveButtonText
    backButtonText
    editButtonText
    documentDescriptionCashlessAgreement
    documentDescriptionEnrollmentAgreement
    documentDescriptionEmployeeStockPurchasePlan
    documentNameCashlessAgreement
    documentNameEnrollmentAgreement
    documentNameProspectusSupplement
    documentDescriptionProspectusSupplement
    documentNameEsppSubPlan
    documentNamePurchaseSummary
    documentDescriptionEsppSubPlan
    documentNamePayrollAuthorizationForm
    documentDescriptionPayrollAuthorizationForm
    documentNameWithdrawalConfirmation
    documentNameUsTax1099B
    documentNameUsTax1099BSupplement
    documentNameUsTax1099BUnofficial
    documentNameUsTax3922
    documentTypeNameStatement
    documentTypeNameContract
    documentTypeNameConfirmation
    documentTypeNameTaxDocument
    documentTypeNameInformation
    documentTypeNameStatementPlural
    documentTypeNameContractPlural
    documentTypeNameConfirmationPlural
    documentTypeNameTaxDocumentPlural
    documentTypeNameInformationPlural
    documentNameEmployeeStockPurchasePlan
    documentNameS8Prospectus
    documentDescriptionS8Prospectus
    documentNameCombinedEsppAndProspectus
    documentNameSellToCoverSupplement
    documentDescriptionSellToCoverSupplement
    documentDescriptionCombinedEsppAndProspectus
    documentNameClearingBrokerStatement
    documentDescriptionClearingBrokerStatement
    documentNamePurchaseStatement
    documentDescriptionPurchaseStatement
    documentNameTaxSummary
    documentDescriptionTaxSummary
    documentNameClearingBrokerConfirm
    documentDescriptionClearingBrokerConfirm
    documentNameCancellationOfWithdrawal
    documentDescriptionCancellationOfWithdrawal
    documentNameInformationDocument
    documentDescriptionInformationDocument
    articleCategorySectionHeadingOverview
    articleCategorySectionHeadingMisc
    articleCategorySectionHeadingTax
    articleCategorySectionHeadingTraining
    articleResourceTypeBasics
    articleResourceTypeVideo
    articleResourceTypeDocuments
    betaLabelForBetaFeature
    offeringPeriodName
    purchasePeriodName
    learnMoreLinkText
    genericErrorPopup {${buildGqlFieldsQuery(DialogGqlHelper)}}
    downloadPageButtonText
    showItemsCountText
    showItemsCountTextWithoutTotal
    encryptedDataDisclaimerText
    downloadExcelButtonText
    downloadCsvButtonText
  `,
    processGqlData: (gqlResponse: unknown) => {
      if (!gqlResponse) {
        return undefined;
      }

      const {
        carverEdisonName,
        yes,
        no,
        trueText,
        falseText,
        cashlessParticipationOn,
        cashlessParticipationOff,
        eligiblePayName,
        percentagePostfix,
        sharesPostfix,
        loadingText,
        allText,
        notAvailableNotApplicable,
        unknownText,
        payFrequencyCalculatorWeekly,
        payFrequencyCalculatorBiweekly,
        payFrequencyCalculatorMonthly,
        payFrequencyCalculatorSemimonthly,
        payFrequencyCalculatorLunar,
        perPurchasePeriod,
        perOfferingPeriod,
        currencyExchangeFxRatePlaceholder,
        saveButtonText,
        backButtonText,
        editButtonText,
        documentDescriptionCashlessAgreement,
        documentDescriptionEnrollmentAgreement,
        documentDescriptionEmployeeStockPurchasePlan,
        documentNameS8Prospectus,
        documentDescriptionS8Prospectus,
        documentNameCashlessAgreement,
        documentNameEnrollmentAgreement,
        documentNameProspectusSupplement,
        documentDescriptionProspectusSupplement,
        documentNameEsppSubPlan,
        documentDescriptionEsppSubPlan,
        documentNamePurchaseSummary,
        documentNamePayrollAuthorizationForm,
        documentDescriptionPayrollAuthorizationForm,
        documentNameWithdrawalConfirmation,
        documentNameUsTax1099B,
        documentNameUsTax1099BSupplement,
        documentNameUsTax1099BUnofficial,
        documentNameUsTax3922,
        documentTypeNameStatement,
        documentTypeNameContract,
        documentTypeNameConfirmation,
        documentTypeNameTaxDocument,
        documentTypeNameInformation,
        documentTypeNameStatementPlural,
        documentTypeNameContractPlural,
        documentTypeNameConfirmationPlural,
        documentTypeNameTaxDocumentPlural,
        documentTypeNameInformationPlural,
        documentNameEmployeeStockPurchasePlan,
        documentNameCombinedEsppAndProspectus,
        documentDescriptionCombinedEsppAndProspectus,
        documentNameSellToCoverSupplement,
        documentDescriptionSellToCoverSupplement,
        documentNameClearingBrokerStatement,
        documentDescriptionClearingBrokerStatement,
        documentNamePurchaseStatement,
        documentDescriptionPurchaseStatement,
        documentNameTaxSummary,
        documentDescriptionTaxSummary,
        documentNameClearingBrokerConfirm,
        documentDescriptionClearingBrokerConfirm,
        documentNameCancellationOfWithdrawal,
        documentDescriptionCancellationOfWithdrawal,
        documentNameInformationDocument,
        documentDescriptionInformationDocument,
        articleCategorySectionHeadingOverview,
        articleCategorySectionHeadingMisc,
        articleCategorySectionHeadingTax,
        articleCategorySectionHeadingTraining,
        articleResourceTypeBasics,
        articleResourceTypeVideo,
        articleResourceTypeDocuments,
        betaLabelForBetaFeature,
        offeringPeriodName,
        purchasePeriodName,
        learnMoreLinkText,
        genericErrorPopup,
        downloadPageButtonText,
        showItemsCountText,
        showItemsCountTextWithoutTotal,
        encryptedDataDisclaimerText,
        downloadExcelButtonText,
        downloadCsvButtonText,
      } = convertDotcmsResponseToRecord(gqlResponse);

      return {
        carverEdisonName: convertDotcmsString(carverEdisonName),
        yes: convertDotcmsString(yes),
        no: convertDotcmsString(no),
        trueText: convertDotcmsString(trueText),
        falseText: convertDotcmsString(falseText),
        cashlessParticipationOn: convertDotcmsString(cashlessParticipationOn),
        cashlessParticipationOff: convertDotcmsString(cashlessParticipationOff),
        eligiblePayName: convertDotcmsString(eligiblePayName),
        percentagePostfix: convertDotcmsString(percentagePostfix),
        sharesPostfix: convertDotcmsString(sharesPostfix),
        loadingText: convertDotcmsString(loadingText),
        allText: convertDotcmsString(allText),
        notAvailableNotApplicable: convertDotcmsString(
          notAvailableNotApplicable,
        ),
        unknownText: convertDotcmsString(unknownText),
        payFrequencyCalculatorWeekly: convertDotcmsString(
          payFrequencyCalculatorWeekly,
        ),
        payFrequencyCalculatorBiweekly: convertDotcmsString(
          payFrequencyCalculatorBiweekly,
        ),
        payFrequencyCalculatorMonthly: convertDotcmsString(
          payFrequencyCalculatorMonthly,
        ),
        payFrequencyCalculatorSemimonthly: convertDotcmsString(
          payFrequencyCalculatorSemimonthly,
        ),
        payFrequencyCalculatorLunar: convertDotcmsString(
          payFrequencyCalculatorLunar,
        ),

        perPurchasePeriod: convertDotcmsString(perPurchasePeriod),
        perOfferingPeriod: convertDotcmsString(perOfferingPeriod),
        currencyExchangeFxRatePlaceholder: convertDotcmsString(
          currencyExchangeFxRatePlaceholder,
        ),
        saveButtonText: convertDotcmsString(saveButtonText),
        backButtonText: convertDotcmsString(backButtonText),
        editButtonText: convertDotcmsString(editButtonText),
        documentDescriptionCashlessAgreement: convertDotcmsString(
          documentDescriptionCashlessAgreement,
        ),
        documentDescriptionEnrollmentAgreement: convertDotcmsString(
          documentDescriptionEnrollmentAgreement,
        ),
        documentNameProspectusSupplement: convertDotcmsString(
          documentNameProspectusSupplement,
        ),
        documentDescriptionProspectusSupplement: convertDotcmsString(
          documentDescriptionProspectusSupplement,
        ),
        documentNameEsppSubPlan: convertDotcmsString(documentNameEsppSubPlan),
        documentDescriptionEsppSubPlan: convertDotcmsString(
          documentDescriptionEsppSubPlan,
        ),
        documentNamePurchaseSummary: convertDotcmsString(
          documentNamePurchaseSummary,
        ),
        documentDescriptionEmployeeStockPurchasePlan: convertDotcmsString(
          documentDescriptionEmployeeStockPurchasePlan,
        ),
        documentNameS8Prospectus: convertDotcmsString(documentNameS8Prospectus),
        documentDescriptionS8Prospectus: convertDotcmsString(
          documentDescriptionS8Prospectus,
        ),
        documentNameCashlessAgreement: convertDotcmsString(
          documentNameCashlessAgreement,
        ),
        documentTypeNameStatement: convertDotcmsString(
          documentTypeNameStatement,
        ),
        documentTypeNameContract: convertDotcmsString(documentTypeNameContract),
        documentNameEnrollmentAgreement: convertDotcmsString(
          documentNameEnrollmentAgreement,
        ),
        documentNamePayrollAuthorizationForm: convertDotcmsString(
          documentNamePayrollAuthorizationForm,
        ),
        documentDescriptionPayrollAuthorizationForm: convertDotcmsString(
          documentDescriptionPayrollAuthorizationForm,
        ),
        documentNameWithdrawalConfirmation: convertDotcmsString(
          documentNameWithdrawalConfirmation,
        ),
        documentNameUsTax1099B: convertDotcmsString(documentNameUsTax1099B),
        documentNameUsTax1099BSupplement: convertDotcmsString(
          documentNameUsTax1099BSupplement,
        ),
        documentNameUsTax1099BUnofficial: convertDotcmsString(
          documentNameUsTax1099BUnofficial,
        ),
        documentNameUsTax3922: convertDotcmsString(documentNameUsTax3922),
        documentTypeNameConfirmation: convertDotcmsString(
          documentTypeNameConfirmation,
        ),
        documentTypeNameTaxDocument: convertDotcmsString(
          documentTypeNameTaxDocument,
        ),
        documentTypeNameInformation: convertDotcmsString(
          documentTypeNameInformation,
        ),
        documentTypeNameStatementPlural: convertDotcmsString(
          documentTypeNameStatementPlural,
        ),
        documentTypeNameContractPlural: convertDotcmsString(
          documentTypeNameContractPlural,
        ),
        documentTypeNameConfirmationPlural: convertDotcmsString(
          documentTypeNameConfirmationPlural,
        ),
        documentTypeNameTaxDocumentPlural: convertDotcmsString(
          documentTypeNameTaxDocumentPlural,
        ),
        documentTypeNameInformationPlural: convertDotcmsString(
          documentTypeNameInformationPlural,
        ),
        documentNameEmployeeStockPurchasePlan: convertDotcmsString(
          documentNameEmployeeStockPurchasePlan,
        ),

        documentNameCombinedEsppAndProspectus: convertDotcmsString(
          documentNameCombinedEsppAndProspectus,
        ),
        documentDescriptionCombinedEsppAndProspectus: convertDotcmsString(
          documentDescriptionCombinedEsppAndProspectus,
        ),
        documentNameSellToCoverSupplement: convertDotcmsString(
          documentNameSellToCoverSupplement,
        ),
        documentDescriptionSellToCoverSupplement: convertDotcmsString(
          documentDescriptionSellToCoverSupplement,
        ),
        documentNameClearingBrokerStatement: convertDotcmsString(
          documentNameClearingBrokerStatement,
        ),
        documentDescriptionClearingBrokerStatement: convertDotcmsString(
          documentDescriptionClearingBrokerStatement,
        ),
        documentNamePurchaseStatement: convertDotcmsString(
          documentNamePurchaseStatement,
        ),
        documentDescriptionPurchaseStatement: convertDotcmsString(
          documentDescriptionPurchaseStatement,
        ),
        documentNameTaxSummary: convertDotcmsString(documentNameTaxSummary),
        documentDescriptionTaxSummary: convertDotcmsString(
          documentDescriptionTaxSummary,
        ),
        documentNameClearingBrokerConfirm: convertDotcmsString(
          documentNameClearingBrokerConfirm,
        ),
        documentDescriptionClearingBrokerConfirm: convertDotcmsString(
          documentDescriptionClearingBrokerConfirm,
        ),
        documentNameCancellationOfWithdrawal: convertDotcmsString(
          documentNameCancellationOfWithdrawal,
        ),
        documentDescriptionCancellationOfWithdrawal: convertDotcmsString(
          documentDescriptionCancellationOfWithdrawal,
        ),
        documentNameInformationDocument: convertDotcmsString(
          documentNameInformationDocument,
        ),
        documentDescriptionInformationDocument: convertDotcmsString(
          documentDescriptionInformationDocument,
        ),
        articleCategorySectionHeadingOverview: convertDotcmsString(
          articleCategorySectionHeadingOverview,
        ),
        articleCategorySectionHeadingMisc: convertDotcmsString(
          articleCategorySectionHeadingMisc,
        ),
        articleCategorySectionHeadingTax: convertDotcmsString(
          articleCategorySectionHeadingTax,
        ),
        articleCategorySectionHeadingTraining: convertDotcmsString(
          articleCategorySectionHeadingTraining,
        ),
        articleResourceTypeBasics: convertDotcmsString(
          articleResourceTypeBasics,
        ),
        articleResourceTypeVideo: convertDotcmsString(articleResourceTypeVideo),
        articleResourceTypeDocuments: convertDotcmsString(
          articleResourceTypeDocuments,
        ),
        betaLabelForBetaFeature: convertDotcmsString(betaLabelForBetaFeature),
        offeringPeriodName: convertDotcmsString(offeringPeriodName),
        purchasePeriodName: convertDotcmsString(purchasePeriodName),
        learnMoreLinkText: convertDotcmsString(learnMoreLinkText),
        genericErrorPopup: DialogGqlHelper.processGqlData(genericErrorPopup),
        downloadPageButtonText: convertDotcmsString(downloadPageButtonText),
        showItemsCountText: convertDotcmsString(showItemsCountText),
        showItemsCountTextWithoutTotal: convertDotcmsString(
          showItemsCountTextWithoutTotal,
        ),
        encryptedDataDisclaimerText: convertDotcmsString(
          encryptedDataDisclaimerText,
        ),
        downloadExcelButtonText: convertDotcmsString(downloadExcelButtonText),
        downloadCsvButtonText: convertDotcmsString(downloadCsvButtonText),
      };
    },
  };
