import { useSelector } from 'react-redux';
import { issuerSelector } from '../../selectors';

export const useIssuerIsWithSellToCover = () => {
  const { jurisdictionByCountryCode } = useSelector(issuerSelector);

  if (jurisdictionByCountryCode) {
    const countriesWithSellToCover = Object.keys(
      jurisdictionByCountryCode,
    ).filter(
      (countryCode) =>
        jurisdictionByCountryCode?.[countryCode]?.sell_to_cover_enabled,
    );

    return !!countriesWithSellToCover.length;
  }

  return false;
};
