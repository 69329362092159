import React, {
  Suspense, useEffect, useState, useLayoutEffect,
} from 'react';
import {
  Switch,
  Route,
  useLocation,
  Redirect,
  useParams,
} from 'react-router-dom';
import { useHistory, useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { lazily } from 'react-lazily';
import { authSelector, issuerSelector, userSelector } from '../selectors';
import { useCookie } from '../hooks/useCookie';
import { useGetIssuerShortname } from '../hooks/use-get-issuer-shortname';
import { DownForMaintenancePage } from '../pages/down-for-maintenance/down-for-maintenance';
import { useDownForMaintenance } from '../hooks/useDownForMaintenance';
import { Page, PageUrlPath } from './page-url-path';
import { HalfMagicLinkHandler } from '../components/half-magic-link-handler/half-magic-link-handler';
import type { IRouterParams } from './router-utils';
import { useIsExistingIssuer } from '../hooks/use-is-existing-issuer';
import { LoadingOverlay } from '../components/abc/loading-overlay/loading-overlay';
import { RedirectIfPageNotAvailable } from '../components/redirect-if-page-not-available';
import { ErrorOccurredScreen } from '../components/root-error-boundary/error-occurred-screen';
import UrlSearchParamKey, {
  HALF_MAGIC_LINK_PARAMS,
} from '../utils/url-search-params/url-search-params';

const { AccountPage } = lazily(
  () => import('../components/abc/account/account-page/account-page'),
);

const { EnrollmentPage } = lazily(
  () => import('../pages/enrollment-abc/wrapper/enrollment-wrapper'),
);
const { PrivacyPolicy } = lazily(
  () => import('../components/abc/footer/privacy-policy/privacy-policy'),
);
const { Homescreen } = lazily(
  () => import('../pages/microsite-welcome/homescreen'),
);
const { FlagEditor } = lazily(() => import('../featureFlags/FlagEditor'));
const { ContractTest } = lazily(
  () => import('../pages/contractTest/contract-test'),
);
const { Disclaimer } = lazily(
  () => import('../components/abc/footer/disclaimer/disclaimer'),
);
const { TermsOfService } = lazily(
  () => import('../components/abc/footer/terms-of-service/terms-of-service'),
);

const { Unsubscribe } = lazily(
  () => import('../pages/unsubscribe/unsubscribe-page'),
);

const { ResourceLibraryPage } = lazily(
  () => import('../pages/microsite-resource-library'),
);

const { MergeLink } = lazily(() => import('../pages/merge-link/MergeLink'));

const { AboutCashlessParticipationPage } = lazily(
  () => import('../pages/enrollment/screens/aboutCashlessParticipationScreen'),
);

const { CalculatorPage } = lazily(
  () => import('../pages/calculator-page-abc/calculator-page'),
);

const { DynamicVariableInternalViewer } = lazily(
  () =>
    import(
      '../dotcms/dynamic-variable-internal-viewer/dynamic-variable-internal-viewer'
    ),
);

const { TaxInformation } = lazily(
  () => import('../components/abc/footer/tax-information/tax-information'),
);
const { NotFoundPage } = lazily(() => import('../pages/404'));
const { UnauthorizedPage } = lazily(() => import('../pages/403'));

const { DefaultPage } = lazily(
  () => import('../pages/default-page-abc/default-page'),
);
const { LoginPage } = lazily(() => import('../pages/abc/login/login-page'));

const { ArticlePage } = lazily(
  () => import('../components/abc/article/article-page/article-page'),
);

const { WealthManagementPage } = lazily(
  () => import('../components/abc/wealth-management/wealth-management-page'),
);

const { EmailVerificationCompletePage } = lazily(
  () =>
    import(
      '../components/abc/email-verification/email-verification-complete-page'
    ),
);

const { AdminPageRoot } = lazily(
  () => import('../components/abc/admin-experience/admin-page/admin-page'),
);

const { AntiBotMagicLinkEnrollmentWelcomePage } = lazily(
  () =>
    import(
      '../pages/anti-bot-magic-link-enrollment-welcome/anti-bot-magic-link-enrollment-welcome'
    ),
);

const { AntiBotBrokerQuestionsLandingPage } = lazily(
  () =>
    import(
      '../pages/anti-bot-broker-questions-landing/anti-bot-broker-questions-landing'
    ),
);

const { StandaloneBrokerQuestions } = lazily(
  () =>
    import('../pages/standalone-broker-questions/standalone-broker-questions'),
);

const { AutoSaleBrokerQuestions } = lazily(
  () =>
    import('../pages/auto-sale-broker-questions/auto-sale-broker-questions'),
);

const { MapTest } = lazily(
  () =>
    import(
      '../components/abc/admin-experience/admin-page-enrollment-stats/enrollment-maps/enrollment-globe-map/map-test'
    ),
);

export default function Router() {
  const { isAuthenticated } = useSelector(authSelector);
  const { getAssetsHadError } = useSelector(issuerSelector);
  const {
    company: { shortname },
  } = useSelector(userSelector);
  const [isIntercomEnabled, setIntercom] = useState(false);
  const [pathname, setPathname] = useState('');
  const location = useLocation();
  const history = useHistory();
  // As far as I can tell, path never would actually be defined here.
  // So in theory it could be deleted with no effect.
  // But in the interest of not breaking legacy code I don't understand,
  // I'm being cautious and leaving it here anyway:
  const { path } = useParams<{ path: string | undefined }>();
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
  const [_, updateMagicLinkCookie] = useCookie('magic_link', false);
  const shortnameFromRoute =
    useRouteMatch<IRouterParams>('/:ticker')?.params.ticker;
  const isExistingIssuer = useIsExistingIssuer(shortnameFromRoute);

  useEffect(() => {
    if (!isAuthenticated || !isIntercomEnabled) return;
    if (
      window &&
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
      (window as any).Intercom &&
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
      typeof (window as any).Intercom === 'function'
    ) {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-call
      (window as any).Intercom('update');
    }
  }, [location, isAuthenticated, isIntercomEnabled]);

  useLayoutEffect(() => {
    if (
      history.location.search.includes('utm_') ||
      history.location.search.includes('?myShare') ||
      history.location.search.includes('?espp')
    ) {
      const currentSearchParams = new URLSearchParams(location.search);

      const isMagicLink =
        HALF_MAGIC_LINK_PARAMS.some((halfMagicLinkParam) =>
          currentSearchParams.has(halfMagicLinkParam)) ||
        currentSearchParams.has(
          UrlSearchParamKey.MAGIC_LINK_EXPIRATION_ISO_TIMESTAMP,
        );

      if (isMagicLink) {
        updateMagicLinkCookie('true');
      }
    }
  }, [history.location, location.search, updateMagicLinkCookie]);

  useEffect(() => {
    if (!isAuthenticated) return;
    if (
      shortname.toLowerCase() === 'pavmed' ||
      shortname.toLowerCase() === 'velfinance' ||
      shortname.toLowerCase() === 'luciddx' ||
      shortname.toLowerCase() === 'companyabc' ||
      shortname.toLowerCase() === 'companya' ||
      shortname.toLowerCase() === 'denali' ||
      shortname.toLowerCase() === 'zevra'
    ) {
      return;
    }
    if (
      window &&
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
      (window as any).Intercom &&
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
      typeof (window as any).Intercom === 'function' &&
      shortname.toLowerCase() === 'aramark'
    ) {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-call
      (window as any).Intercom('boot', {
        app_id: 'wia52sgs',
      });
      setIntercom(true);
    }
    if (
      window &&
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
      (window as any).Intercom &&
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
      typeof (window as any).Intercom === 'function' &&
      shortname.toLowerCase() === 'outsetmedical'
    ) {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-call
      (window as any).Intercom('boot', {
        app_id: 'f1ite0gw',
      });
      setIntercom(true);
    }
    if (
      window &&
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
      (window as any).Intercom &&
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
      typeof (window as any).Intercom === 'function' &&
      shortname.toLowerCase() === 'rbglobal'
    ) {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-call
      (window as any).Intercom('boot', {
        app_id: 'j19io1qi',
      });
      setIntercom(true);
    }
  }, [isAuthenticated, shortname]);

  useEffect(
    () =>
      (typeof path === 'string' && path
        ? setPathname(path.toLowerCase())
        : setPathname(
          location.pathname === '/'
            ? '/'
            : location.pathname.split('/')[1].toLowerCase(),
        )),
    [location.pathname, path],
  );

  const inferredCompanyName = useGetIssuerShortname();
  const downForMaintenance = useDownForMaintenance();

  // Ignore the down for maintenance flag if the current page URL is for the flag
  // editor:
  if (
    downForMaintenance &&
    !location.pathname.startsWith('/ce-internal/flags')
  ) {
    return <DownForMaintenancePage />;
  }

  if (inferredCompanyName === 'htt') {
    return (
      <Suspense fallback={<LoadingOverlay />}>
        <Switch>
          <NotFoundPage />
        </Switch>
      </Suspense>
    );
  }

  if (inferredCompanyName === 'kempharm') {
    // Replace kempharm with zevra in the URL:
    const currentPath = window.location.href;
    // Case insensitive regex:
    const newPath = currentPath.replace(/kempharm/i, 'zevra');
    window.location.href = newPath;
  }

  if (getAssetsHadError) {
    return <ErrorOccurredScreen />;
  }

  return (
    <Suspense fallback={<LoadingOverlay />}>
      <HalfMagicLinkHandler>
        {/* RedirectIfPageNotAvailable must be inside of HalfMagicLinkHandler: */}
        <RedirectIfPageNotAvailable />
        <Switch>
          {!!shortnameFromRoute && !isExistingIssuer && (
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            <Route path="*" component={NotFoundPage as React.FC} />
          )}
          {!!isAuthenticated &&
            !location.pathname.includes('ce-internal') &&
            !shortname.toLocaleLowerCase().includes('outsetmedical') &&
            !!shortname &&
            location.pathname.split('/')[1].toLocaleLowerCase() !==
              shortname.toLocaleLowerCase() &&
            !['/', '/documents'].includes(
              location.pathname.toLocaleLowerCase(),
            ) && <UnauthorizedPage />}

          {/* <Route path='/:ticker/test' component={Test} /> */}
          <Route
            path={PageUrlPath[Page.ACCOUNT_PAGE]}
            component={AccountPage}
          />

          <Route
            path={PageUrlPath[Page.CE_INTERNAL_DYNAMIC_VARIABLE_VIEWER]}
            component={DynamicVariableInternalViewer}
          />

          <Route
            exact
            path={PageUrlPath[Page.TAX_INFORMATION]}
            component={TaxInformation}
          />
          <Route
            exact
            path={PageUrlPath[Page.UNSUBSCRIBE]}
            component={Unsubscribe}
          />

          {pathname === 'aramark' && (
            <Redirect from="/:ticker/europe" to="/:ticker" />
          )}

          {pathname?.includes('testco') && (
            <Route
              exact
              path="/:ticker/ce-internal/map-test"
              component={MapTest}
            />
          )}

          <Route
            exact
            path={PageUrlPath[Page.ENROLLMENT_PAGE]}
            component={EnrollmentPage}
          />

          <Route exact path="/ce-internal/flags" component={FlagEditor} />
          <Route
            exact
            path={PageUrlPath[Page.ANTI_BOT_MAGIC_LINK_ENROLLMENT_WELCOME]}
            component={AntiBotMagicLinkEnrollmentWelcomePage}
          />
          <Route
            exact
            path={PageUrlPath[Page.ANTI_BOT_BROKER_QUESTIONS_REDIRECT]}
            component={AntiBotBrokerQuestionsLandingPage}
          />
          <Route
            exact
            path={PageUrlPath[Page.STANDALONE_BROKER_QUESTIONS]}
            component={StandaloneBrokerQuestions}
          />
          <Route
            exact
            path={PageUrlPath[Page.AUTO_SALE_BROKER_QUESTIONS]}
            component={AutoSaleBrokerQuestions}
          />

          <Route path="/hrisconnect">
            <MergeLink />
          </Route>

          <Redirect
            from="/:ticker/learn/espp-calculator"
            to={{
              pathname: '/:ticker/multimedia-tools',
              state: { status: 301 },
            }}
          />

          <Route path={PageUrlPath[Page.TEMP_PDF_VIEWER_TEST]}>
            <ContractTest />
          </Route>

          <Route exact path={PageUrlPath[Page.RESOURCE_LIBRARY_ID]}>
            <ArticlePage />
          </Route>

          <Route
            exact
            path={PageUrlPath[Page.RESOURCE_LIBRARY]}
            component={ResourceLibraryPage}
          />
          <Redirect
            from="/resource-library/:ticker"
            to={{
              pathname: PageUrlPath[Page.RESOURCE_LIBRARY],
              state: { status: 301 },
            }}
          />

          <Route
            path={PageUrlPath[Page.CALCULATOR]}
            component={CalculatorPage}
          />
          <Redirect
            from="/multimedia-tools/:ticker"
            to={{
              pathname: '/:ticker/multimedia-tools',
              state: { status: 301 },
            }}
          />

          <Redirect
            from="/:ticker/about-cashless-participation"
            to={{
              pathname: '/:ticker/about-cashless',
              state: { status: 301 },
            }}
          />
          <Redirect
            from="/about-cashless-participation/:ticker"
            to={{
              pathname: '/:ticker/about-cashless-participation',
              state: { status: 301 },
            }}
          />

          <Route
            exact
            path={PageUrlPath[Page.ABOUT_CASHLESS]}
            component={AboutCashlessParticipationPage}
          />
          <Redirect
            from="/about-cashless/:ticker"
            to={{
              pathname: PageUrlPath[Page.ABOUT_CASHLESS],
              state: { status: 301 },
            }}
          />

          <Redirect
            from="/:ticker/learn"
            to={{
              pathname: '/:ticker/resource-library',
              state: { status: 301 },
            }}
          />

          <Route path={PageUrlPath[Page.ADMIN_PAGE]}>
            <AdminPageRoot />
          </Route>

          <Route path={PageUrlPath[Page.ACCOUNT_PAGE]}>
            <AccountPage />
          </Route>
          <Redirect
            from="/account/:ticker"
            to={{
              pathname: PageUrlPath[Page.ACCOUNT_PAGE],
              state: { status: 301 },
            }}
          />

          <Route
            path={PageUrlPath[Page.PRIVACY_POLICY]}
            component={PrivacyPolicy}
          />
          <Redirect
            from="/privacy/:ticker"
            to={{
              pathname: PageUrlPath[Page.PRIVACY_POLICY],
              state: { status: 301 },
            }}
          />

          <Route
            path={PageUrlPath[Page.IMPORTANT_INFORMATION]}
            component={Disclaimer}
          />
          <Redirect
            from="/disclaimer/:ticker"
            to={{
              pathname: PageUrlPath[Page.IMPORTANT_INFORMATION],
              state: { status: 301 },
            }}
          />

          <Route
            path={PageUrlPath[Page.TERM_OF_SERVICE]}
            component={TermsOfService}
          />
          <Redirect
            from="/terms-of-service/:ticker"
            to={{
              pathname: PageUrlPath[Page.TERM_OF_SERVICE],
              state: { status: 301 },
            }}
          />

          <Route
            exact
            path={PageUrlPath[Page.WEALTH_MANAGEMENT]}
            component={WealthManagementPage}
          />

          <Route
            exact
            path={PageUrlPath[Page.EMAIL_VERIFICATION_COMPLETE]}
            component={EmailVerificationCompletePage}
          />

          <Route exact path="/not-found">
            <NotFoundPage />
          </Route>

          <Route exact path="/403">
            <UnauthorizedPage />
          </Route>

          <Redirect
            exact
            from="/documents"
            to={{ pathname: '/', state: { status: 301 } }}
          />

          <Route exact path={PageUrlPath[Page.LOGIN_PAGE]}>
            <LoginPage />
          </Route>

          <Route exact path="/:ticker/former-employee-log-in">
            <LoginPage />
          </Route>

          {/* Not sure why we have this with the . at the end - maybe someone accidentally typo'd an
          email at some point and they wanted to make the link work? */}
          <Route path="/:ticker." component={Homescreen} />
          <Route path="/:ticker" component={Homescreen} />

          <Route path={PageUrlPath[Page.DEFAULT]} component={DefaultPage} />
        </Switch>
      </HalfMagicLinkHandler>
      {isAuthenticated && (
        // Google Tag Manager looks for this element with id="loggedIn"
        // to tell if a user is logged in.
        // The element must be considered visible, so it needs a height
        // and width. This style makes it a transparent 1x1 pixel in
        // the corner that does not block clicks, so GTM can see
        // it, but it won't affect the user experience at all.
        <div
          className="auth-status"
          id="loggedIn"
          style={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            height: '1px',
            width: '1px',
            backgroundColor: 'transparent',
            pointerEvents: 'none',
          }}
        />
      )}
    </Suspense>
  );
}
