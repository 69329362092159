import { useSelector } from 'react-redux';
import { issuerSelector } from '../../selectors';

const useIsExistingIssuer = (ticker?: string): boolean => {
  const { issuerNotFoundError } = useSelector(issuerSelector);
  if (!ticker) {
    return false;
  }

  if (issuerNotFoundError) {
    return false;
  }

  return true;
};

export default useIsExistingIssuer;
