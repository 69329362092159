import { PayFrequency } from '../selectors/interfaces';
import { config } from './config';

export const BASE_URL = config.api;
export const ASSETS_CDN_URL = config.assetsCdnUrl;
export const DOTCMS_CLOUD_URL = config.cmsUrl;

// @testing
export const INACTIVITY_WARNING_MS = 1500000;
export const INACTIVITY_SIGNOUT_MS = 1800000;
export const SESSION_WARNING_OFFSET_MS = 600000;
// export const SESSION_WARNING_OFFSET_MS = 30000 // 30 seconds for testing
// export const INACTIVITY_WARNING_MS = 600000
// export const INACTIVITY_SIGNOUT_MS = 900000

// @testing
// export const LOCKOUT_LENGTH = 9000
// current business rule: 3 days
export const LOCKOUT_LENGTH = 1000 * 60 * 60 * 24 * 3;
export const LOCAL_STORAGE_KEY = 'ce_cp';

export const expectedTickers = [
  'aramark',
  'carveredison',
  'PAVmed',
  'revance',
  'abccompany',
];

export const GDPR_COUNTRIES = [
  'AUT',
  'BEL',
  'BGR',
  'HRV',
  'CYP',
  'CZE',
  'DNK',
  'EST',
  'FIN',
  'FRA',
  'DEU',
  'GRC',
  'HUN',
  'IRL',
  'ITA',
  'LVA',
  'LTU',
  'LUX',
  'MLT',
  'NLD',
  'POL',
  'PRT',
  'ROU',
  'SVK',
  'SVN',
  'ESP',
  'SWE',
  'GBR',
];

export const CURRENCY = {
  GBR: 'GBP',
  USA: 'USD',
  PR: 'USD',
  US: 'USD',
  CAN: 'CAD',
  CA: 'CAD',
  IRL: 'EUR',
};

export const COLORS = {
  GRAY97: '#f7f7f7',
  GRAY2: '#cacaca',
} as const;

export const TYPOGRAPHY_SET = {
  H5: {
    fontFamily: 'Lato',
    fontSize: '30px',
    lineHeight: '36px',
    fontWeight: '700',
    color: 'var(--black-real)',
  },
  REGULAR_TEXT: {
    fontFamily: 'Lato',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '400',
    margin: '20px 0',
    color: 'var(--black-real)',
    '@media (max-width: 900px)': {
      fontSize: '14px',
      lineHeight: '19px',
    },
  },
} as const;

export enum ResponseMessageFromServer {
  INVALID_AUTH = 'invalidAuth',
  SUCCESS = 'success',
  RESEND_SUCCESS = 'resendSuccess',
  EMAIL_VERIFIED = 'emailVerified',
  EMAIL_EXISTS = 'emailAlreadyExists',
  EMAIL_NOT_ALLOWED = 'emailNotAllowed',
  INVALID_FILE_FORMAT = 'invalidFileFormat',
  INVALID_FILE_SIZE = 'invalidFileSize',
}
// These are not exact (e.g. there are actually like 52.17 weeks per year)
// Maybe we should make them more precise?
export const PAYCHECKS_PER_YEAR: Readonly<{
  [frequency in PayFrequency]: number;
}> = {
  [PayFrequency.WEEKLY]: 52,
  [PayFrequency.BIWEEKLY]: 26,
  [PayFrequency.MONTHLY]: 12,
  [PayFrequency.SEMIMONTHLY]: 24,
  [PayFrequency.LUNAR]: 13,
};
