import LogRocket from 'logrocket';
import type { PropsWithChildren } from 'react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { authSelector, userSelector } from '../selectors';
import { config } from '../utils/config';

export const LogrocketUserInfoSync: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { isAuthenticated } = useSelector(authSelector);
  const { id, name, company } = useSelector(userSelector);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (!config.useLogrocket) {
      return;
    }

    if (isAuthenticated) {
      if (!isLoggedIn) {
        LogRocket.identify(`${id}`, {
          name: `${name.first} ${name.last}`,
          email: `${name.email}`,
          companyName: `${company.shortname}`,
        });
        setIsLoggedIn(true);
      }
    } else {
      setIsLoggedIn(false);
    }
  }, [isAuthenticated, id, name, company, isLoggedIn]);

  return <div>{children}</div>;
};
