import { useDispatch } from 'react-redux';
import { Dialog, Box } from '@mui/material';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useCallback } from 'react';
import HtmlBox from '../../../component_library/components/HtmlBox/HtmlBox';
import { DataElementId } from '../../../constants/data-element-tracking-ids';
import Typography, {
  TypographyVariant,
} from '../../../component_library/components/Typography';
import { Button } from '../../../component_library/components/Button';
import { ButtonVariant } from '../../../component_library/components/Button/button-style-helper';
import { useInactivityNoticeModal } from './hooks';
import { setInactivityModal } from '../../../slices/issuer-reducer';

const InactivityNoticeModal = () => {
  const [isDialogOpen, setDialogOpen] = useState(true);
  const { theme, data, loading } = useInactivityNoticeModal();
  const dispatch = useDispatch();

  const handleKeepMeLoggedIn = useCallback(() => {
    dispatch(setInactivityModal(''));
    setDialogOpen(false);
  }, [dispatch, setDialogOpen]);

  if (loading) {
    return null;
  }

  const MODAL_WINDOW_WRAPPER_STYLE = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '558px',
    padding: '0 64px',
    '& p': {
      textAlign: 'center',
    },
  };

  const CONTENT_STYLE = {
    marginTop: '14px',
  };

  return (
    <Dialog
      open={isDialogOpen}
      aria-label="user inactivity notice modal"
      onClose={() => setDialogOpen(false)}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      }}
    >
      <Box
        sx={{
          alignSelf: 'flex-end',
          margin: '12px',
          color: theme.colors.grayscale.inactiveGrey1,
        }}
      >
        <FontAwesomeIcon icon={faXmark} onClick={() => setDialogOpen(false)} />
      </Box>
      <Box sx={MODAL_WINDOW_WRAPPER_STYLE}>
        <Typography
          variant={TypographyVariant.H3}
          additionalStyles={{ marginTop: '40px' }}
        >
          {data?.headline ? (
            <HtmlBox html={data?.headline} />
          ) : (
            <Typography>You were signed out due to inactivity</Typography>
          )}
        </Typography>
        <Typography
          variant={TypographyVariant.MEDIUM_BODY_REGULAR}
          additionalStyles={CONTENT_STYLE}
        >
          <HtmlBox html={data?.content} />
        </Typography>
        <Button
          dataElementId={DataElementId.INACTIVITY_NOTICE_MODAL_WINDOW_BUTTON}
          type="button"
          variant={ButtonVariant.PRIMARY}
          theme={theme}
          size="default"
          additionalStyles={{
            width: 'fit-content',
            margin: '40px 0 20px',
          }}
          onPress={handleKeepMeLoggedIn}
        >
          {data?.buttonSign ? (
            <HtmlBox html={data?.buttonSign} />
          ) : (
            'Take me home'
          )}
        </Button>
      </Box>
    </Dialog>
  );
};

export default InactivityNoticeModal;
