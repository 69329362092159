/** @jsxImportSource @emotion/react */
// see - https://www.figma.com/file/pv2HV6DTSKY612VWeYWYdL/CE22-Design-System?node-id=3%3A10
import type { PropsWithChildren } from 'react';
import React from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link as ReactRouterLink } from 'react-router-dom';
import type { Interpolation, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import type { Theme as MuiTheme } from '@mui/material';
import type { ITheme, ThemeColor } from '../../styles/theme';
import { theme as defaultTheme } from '../../styles/theme';
import Typography, { TypographyVariant } from '../Typography/Typography';
import type { IPropsWithTestId } from '../../../constants/data-element-tracking-ids';
import { ELEMENT_ID_DATA_ATTRIBUTE } from '../../../constants/data-element-tracking-ids';
import type { SettingBasedOnBreakpoints } from '../../../hooks/use-setting-based-on-breakpoints';

const StyledLink = styled(ReactRouterLink)`
  text-decoration: none;
`;

interface IProps extends IPropsWithTestId {
  variant?: TypographyVariant | SettingBasedOnBreakpoints<TypographyVariant>;
  noUnderline?: boolean;
  inlineLink?: boolean;
  theme?: ITheme;
  muiTheme?: MuiTheme;
  color?: ThemeColor;
  additionalStyles?: Interpolation<Theme>;
}

const Link: React.FC<
PropsWithChildren<IProps & Omit<LinkProps, 'style' | 'children' | 'color'>>
> = ({
  variant = TypographyVariant.LINKS_MEDIUM,
  inlineLink = false,
  noUnderline = false,
  children,
  theme = defaultTheme,
  muiTheme,
  color,
  additionalStyles = {},
  dataElementId,
  ...props
}) => {
  const linkStyles = {
    textDecorationColor: color,
  };

  const textStyles = {
    display: inlineLink ? 'contents' : '',
    textDecorationLine: noUnderline ? 'none' : undefined,
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledLink
      css={[additionalStyles, linkStyles]}
      {...{ [ELEMENT_ID_DATA_ATTRIBUTE]: dataElementId }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Typography
        color={color}
        variant={variant}
        muiTheme={muiTheme}
        theme={theme}
        additionalStyles={textStyles}
      >
        {children}
      </Typography>
    </StyledLink>
  );
};

export default Link;
