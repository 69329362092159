import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { issuerSelector } from '../selectors';
import { useGetIssuerShortname } from './use-get-issuer-shortname';
import { ASSETS_CDN_URL } from '../utils/constants';

const FAVICON_TYPE = 'image/x-icon';

function setFavicon(favicon: string) {
  const epochTime = new Date().getTime() / 1000;
  const faviconHref = `${favicon}?k=${epochTime}`;
  const link = document.querySelector<HTMLLinkElement>(
    "link[rel~='shortcut icon']",
  );
  if (!link) {
    const faviconElement = document.createElement('link');
    faviconElement.setAttribute('type', FAVICON_TYPE);
    faviconElement.setAttribute('rel', 'icon');
    faviconElement.setAttribute('id', 'favicon');
    faviconElement.setAttribute('href', faviconHref);
    document.getElementsByTagName('head')[0].appendChild(faviconElement);
  } else {
    link.href = faviconHref;
  }
}

function useGetExpectedFavicon(favicon: string, predefinedFavicon?: string) {
  const issuerShortname = useGetIssuerShortname() ?? '';

  if (predefinedFavicon) {
    return predefinedFavicon;
  }

  if (favicon) {
    return favicon;
  }

  return `${ASSETS_CDN_URL}/${issuerShortname}/favicon.ico`;
}

export const useFavicon = (predefinedFavicon?: string) => {
  const { favicon } = useSelector(issuerSelector);

  const moreExpectedFavicon = useGetExpectedFavicon(favicon, predefinedFavicon);

  useEffect(() => {
    setFavicon(moreExpectedFavicon);
    // Cleanup function: Change the favicon back to what it was before.
    // Otherwise it can get stuck on the alternate favicon.
    return () => setFavicon(moreExpectedFavicon);
  }, [moreExpectedFavicon]);

  return {
    favicon: {
      href: moreExpectedFavicon,
      type: FAVICON_TYPE,
    },
  };
};
