import { useSelector } from 'react-redux';
import { useMemo, useCallback, useContext } from 'react';
import { match } from 'ts-pattern';
import { DateTime } from 'luxon';
import type {
  JSONSerializableObject,
  JSONSerializableValue,
} from '../utils/json-types';
import { issuerSelector, userSelector } from '../selectors/index';
import { recursivelyReplaceStringWithMapping } from './recursively-replace-string-with-mapping';
import { useGetIssuerShortname } from '../hooks/use-get-issuer-shortname';
import {
  formatToLocale,
  useDateFormatter,
} from '../hooks/useDateFormatter/useDateFormatter';
import useGetUserStatesInPeriods from '../hooks/use-get-user-states-in-enrollment/use-get-user-states-in-periods';
import { GlobalTextVariablesContext } from './contexts/global-text-variables-context';
import { usePlanDetails } from '../hooks/usePlanDetails';
import { useGetJurisdictionData } from '../hooks/use-get-jurisdiction-data';
import { ChangeLimitPeriod } from '../selectors/interfaces';
import { useCurrencyConversion } from '../hooks/use-currency-conversion';
import { DateFormat } from '../constants/dateFormat';
import { useLocale } from '../locale/use-locale';
import { useCurrencyFormatter } from '../hooks/useCurrencyFormatter';
import type { ConditionalCmsConditionArgs } from './conditional-cms-strings';
import {
  CONDITIONAL_CMS_LOGICAL_NOT_PREFIX,
  CONDITIONAL_CMS_STRING_CONDITIONS,
} from './conditional-cms-strings';
import usePlanIsEsppOnly from '../hooks/use-is-espp-only/use-plan-is-espp-only';
import { useIsCashlessMatchPlan } from '../hooks/useIsCashlessMatchPlan';
import {
  EnrollmentPlanEspp,
  useGetEnrollmentPlan,
} from '../hooks/use-get-enrollment-plan';
import { useGetPeriodsDatesInfo } from '../hooks/use-get-periods-info';
import { useGetExpressionOfInterestInfo } from '../hooks/use-get-expression-of-interest-info';
import { useGetTheme } from '../hooks/use-get-theme';
import { replaceVariablesInString } from './dotcms-models/converters-from-dotcms';
import { useGetUserLocation } from '../hooks/use-get-user-location/use-get-user-location';
import { useBooleanUrlSearchParam } from '../utils/url-search-params/use-query-url-search-params';
import UrlSearchParamKey from '../utils/url-search-params/url-search-params';
import { useIsSellToCoverEnabled } from '../hooks/use-is-sell-to-cover-enabled/use-is-sell-to-cover-enabled';

// All keys MUST begin with "ce_", or our automated translation in the CMS
// may translate the key, breaking this functionality for other languages.
// Keys may contain ascii letters, numbers, or underscore.
// If you want to add support for additional characters, you will need to update
// the regex in recursivelyReplaceStringWithMapping
export enum DynamicVariableKey {
  ISSUER_DISPLAY_NAME = 'ce_issuer_display_name',
  ISSUER_SHORT_NAME = 'ce_issuer_short_name',
  ISSUER_LEGAL_REGISTERED_NAME = 'ce_issuer_registered_name',
  STOCK_ADMIN = 'ce_stock_admin',
  EXTERNAL_ENROLLMENT_PROVIDER_NAME = 'ce_external_enrollment_provider_name',
  EXTERNAL_ENROLLMENT_URL = 'ce_external_enrollment_url',
  PRODUCT_NAME = 'ce_product_name',
  CONTACT_EMAIL = 'ce_contact_email',
  ISSUER_PLAN_NAME = 'ce_issuer_plan_name',
  ENROLLMENT_START_DATE = 'ce_enrollment_start_date',
  ENROLLMENT_END_DATE = 'ce_enrollment_end_date',
  EXTERNAL_ENROLLMENT_START_DATE = 'ce_external_enrollment_start_date',
  EXTERNAL_ENROLLMENT_END_DATE = 'ce_external_enrollment_end_date',
  NEXT_ENROLLMENT_START_DATE = 'ce_next_enrollment_start_date',
  NEXT_ENROLLMENT_END_DATE = 'ce_next_enrollment_end_date',
  CURRENT_PURCHASE_DATE = 'ce_current_purchase_date',
  NEXT_OFFER_START_DATE = 'ce_next_offer_start_date',
  CURRENT_CONTRIBUTION_PERCENTAGE = 'ce_current_contribution_percentage',
  GENERAL_EMPLOYEE_STOCK_PURCHASE_PLAN_PRODUCT_NAME_LONG = 'ce_general_product_name_long',
  GENERAL_EMPLOYEE_STOCK_PURCHASE_PLAN_PRODUCT_NAME_SHORT = 'ce_general_product_name',
  NEXT_CONTRIBUTION_PERCENTAGE = 'ce_next_contribution_percentage',
  PLAN_MAX = 'ce_plan_max_percentage',
  CASHLESS_PARTICIPATION = 'ce_cashless_participation',
  CASHLESS_PARTICIPATION_TEXT_ON = 'ce_cashless_participation_on',
  CASHLESS_PARTICIPATION_TEXT_OFF = 'ce_cashless_participation_off',
  CASHLESS_PARTICIPATION_TEXT_ON_OR_OFF = 'ce_cashless_participation_on_or_off',
  ELIGIBLE_PAY_NAME_TEXT = 'ce_eligible_pay_name',
  DESIGNATED_SALE_PRICE_DSP_RATE = 'ce_dsp_rate',
  MAX_CASHLESS_CONTRIBUTION_PERCENT = 'ce_cashless_plan_max',
  MIN_CASHLESS_CONTRIBUTION_PERCENT = 'ce_cashless_plan_min',
  MIN_ESPP_CONTRIBUTION_PERCENT = 'ce_espp_min_contribution_percent',
  MAX_ESPP_CONTRIBUTION_PERCENT = 'ce_espp_max_contribution_percent',
  CASHLESS_MAX_BOOST_PERCENT = 'ce_cashless_max_boost_percent',
  CASHLESS_DIFF_BETWEEN_MAX_BOOST_AND_MIN_PERCENT = 'ce_cashless_diff_max_boost_and_min_percent',
  PAY_FREQUENCY_WEEKLY_TEXT = 'ce_pay_frequency_weekly',
  PAY_FREQUENCY_BIWEEKLY_TEXT = 'ce_pay_frequency_biweekly',
  PAY_FREQUENCY_MONTHLY_TEXT = 'ce_pay_frequency_monthly',
  PAY_FREQUENCY_SEMIMONTHLY_TEXT = 'ce_pay_frequency_semimonthly',
  PAY_FREQUENCY_LUNAR_TEXT = 'ce_pay_frequency_lunar',
  PAY_FREQUENCY_TEXT = 'ce_pay_frequency',
  HOURS_WORKED_PER_WEEK = 'ce_hours_worked_weekly',
  PLAN_DISCOUNT_PERCENT = 'ce_plan_discount',
  PLAN_AFTER_DISCOUNT_PERCENT = 'ce_afterdiscount_rate',
  PLAN_MIN_HOURS_WORKED_FOR_ELIGIBILITY = 'ce_eligibility_minhours',
  PRODUCT_NAME_LONG = 'ce_product_name_long',
  MONTHS_IN_PURCHASE_PERIOD = 'ce_purchaseperiod_months',
  MONTHS_IN_OFFERING_PERIOD = 'ce_offeringperiod_months',
  PURCHASES_IN_OFFERING_PERIOD = 'ce_num_purchases_in_op',
  OFFERING_PERIOD_START_FULL_DATE_NO_YEAR = 'ce_opstart_fulldate_noyear',
  OFFERING_PERIOD_END_FULL_DATE_NO_YEAR = 'ce_opend_fulldate_noyear',
  OFFERING_PERIOD_START_FULL_DATE = 'ce_opstart_fulldate',
  OFFERING_PERIOD_END_FULL_DATE = 'ce_opend_fulldate',
  FUTURE_OFFERING_PERIOD_START_FULL_DATE_NO_YEAR = 'ce_future_opstart_fulldate_noyear',
  FUTURE_OFFERING_PERIOD_END_FULL_DATE_NO_YEAR = 'ce_future_opend_fulldate_noyear',
  FUTURE_OFFERING_PERIOD_START_FULL_DATE = 'ce_future_opstart_fulldate',
  FUTURE_OFFERING_PERIOD_END_FULL_DATE = 'ce_future_opend_fulldate',
  FUTURE_ENROLLMENT_END_DATE_TIME_ZONE = 'ce_future_enrollment_end_date_time_zone',
  ENROLLMENT_START_FULL_DATE_NO_YEAR = 'ce_enrollstart_fulldate_noyear',
  ENROLLMENT_END_FULL_DATE_NO_YEAR = 'ce_enrollend_fulldate_noyear',
  ENROLLMENT_EOI_DEADLINE = 'ce_enrollment_eoi_deadline',
  PURCHASE_PERIOD_START_FULL_DATE_NO_YEAR = 'ce_purchase_period_start_fulldate_noyear',
  PURCHASE_PERIOD_END_FULL_DATE_NO_YEAR = 'ce_purchase_period_end_fulldate_noyear',
  PURCHASE_DATE_FULL_DATE = 'ce_purchasedate_fulldate',
  POTENTIAL_FUTURE_WITHDRAWN_AT_DATE_FULL_DATE = 'ce_potential_future_date_to_be_withdrawn_at_fulldate',
  RESET_CHANGE_WINDOW_END_DATETIME = 'ce_reset_change_window_end_datetime',
  TRADE_RESTRICTION_WINDOW_START_DATETIME = 'ce_trade_restriction_window_start_datetime',
  TRADE_RESTRICTION_WINDOW_END_DATETIME = 'ce_trade_restriction_window_end_datetime',
  LAST_DAY_TO_WITHDRAW_FROM_CURRENT = 'ce_last_day_to_withdraw_from_current',
  CONTRIBUTION_CHANGE_DEADLINE = 'ce_contribution_change_deadline',
  CONTRIBUTION_CHANGES_REMAINING = 'ce_contribution_changes_remaining',
  CONTRIBUTION_CHANGES_FREQUENCY = 'ce_contribution_changes_frequency',
  CONTRIBUTION_INCREASES_REMAINING = 'ce_contribution_increases_remaining',
  CONTRIBUTION_INCREASES_FREQUENCY = 'ce_contribution_increases_frequency',
  CONTRIBUTION_DECREASES_REMAINING = 'ce_contribution_decreases_remaining',
  CONTRIBUTION_DECREASES_FREQUENCY = 'ce_contribution_decreases_frequency',
  CONTRIBUTION_CHANGE_COUNT_RESETS_PER_PURCHASE_OR_PER_OFFERING = 'ce_change_frequency',
  SUSPENSION_COUNT_RESETS_PER_PURCHASE_OR_PER_OFFERING = 'ce_suspension_frequency',
  PURCHASE_DATE_FULL_DATE_NO_YEAR = 'ce_purchasedate_fulldate_noyear',
  FUTURE_PURCHASE_DATE_FULL_DATE = 'ce_future_purchasedate_fulldate',
  FUTURE_PURCHASE_DATE_FULL_DATE_NO_YEAR = 'ce_future_purchasedate_fulldate_noyear',
  FUTURE_PURCHASE_PERIOD_START_FULL_DATE_NO_YEAR = 'ce_future_purchase_period_start_fulldate_noyear',
  FUTURE_PURCHASE_PERIOD_END_FULL_DATE_NO_YEAR = 'ce_future_purchase_period_end_fulldate_noyear',
  CASHLESS_FEE_PERCENT = 'ce_fee_rate',
  LEARN_MORE_LINK_TEXT = 'ce_learn_more_link_text',
  USER_FIRST_NAME = 'ce_first_name',
  PLAN_TIME_ZONE = 'ce_plan_timezone',
  FIRST_MINUTE_OF_DAY = 'ce_first_minute_of_day',
  LAST_MINUTE_OF_DAY = 'ce_last_minute_of_day',
  CURRENCY_EXCHANGE_FX_RATE = 'ce_fxrate',
  LOGIN_EMAIL = 'ce_employee_login_email',
  STOCK_ADMIN_LOGIN_URL = 'ce_stock_admin_login_url',
  STOCK_ADMIN_TAX_URL = 'ce_stock_admin_tax_information_url',
  STOCK_ADMIN_TERMS_URL = 'ce_stock_admin_terms_and_conditions_url',
  STOCK_ADMIN_POLICY_URL = 'ce_stock_admin_privacy_policy_url',
  WEALTH_MANAGEMENT_FIRM_NAME = 'ce_wealth_management_firm_name',
  FIRST_EVER_OFFERING_PERIOD_START_DATE = 'ce_initial_op_start_date',
  REFERRAL_REWARD_OFFER_VALUE = 'ce_issuer_referral_prize',
  RETURN_ON_INVESTMENT_PERCENT = 'ce_return_on_investment_percent',
  EOI_FORM_URL = 'ce_eoi_form_url',
  EMPLOYEE_COMPANY_ID = 'ce_employee_company_id',
  PRIMARY_COLOR = 'ce_primary_color',
  SECONDARY_COLOR = 'ce_secondary_color',
}

enum DataConstants {
  CASHLESS_PARTICIPATION_CONST = 'Cashless Participation',
  CASHLESS_MATCH_CONST = 'Cashless Match',
}

/** An explanation of each dynamic variable (should be understandable by non-engineers) */
export const DYNAMIC_VARIABLE_DOCUMENTATION: {
  [key in DynamicVariableKey]: string;
} = {
  [DynamicVariableKey.ISSUER_DISPLAY_NAME]:
    'The display name for the issuer. Often a nickname instead of official corporate name.',
  [DynamicVariableKey.STOCK_ADMIN]:
    "The stock admin for the plan, e.g. 'E*TRADE'",
  [DynamicVariableKey.EXTERNAL_ENROLLMENT_PROVIDER_NAME]:
    "The name of the provider of external ESPP enrollment, e.g. 'Workday' or 'E*TRADE'",
  [DynamicVariableKey.EXTERNAL_ENROLLMENT_URL]:
    'The URL where users should go for external ESPP enrollment',
  [DynamicVariableKey.ISSUER_LEGAL_REGISTERED_NAME]:
    "The issuer's registered (legal) name, probably ending in 'Inc.' or 'LLC' or something",
  [DynamicVariableKey.PRODUCT_NAME]:
    "The name of the ESPP, often just 'ESPP' but sometimes something like 'myShare'",
  [DynamicVariableKey.CONTACT_EMAIL]:
    "The email address where an issuer's employees should reach out to get support from Carver Edison.",
  [DynamicVariableKey.ISSUER_SHORT_NAME]:
    "The shortName (a version of the issuer's name that shows up in the URL, like 'luciddx' shows up in espp.com/luciddx for Lucid Diagnostics",
  [DynamicVariableKey.ISSUER_PLAN_NAME]:
    "The ESPP plan name, like '2021 Employee Stock Purchase Plan'",
  [DynamicVariableKey.CASHLESS_PARTICIPATION]:
    'Cashless Participation text constant',
  [DynamicVariableKey.ENROLLMENT_START_DATE]: 'Enrollment start date',
  [DynamicVariableKey.ENROLLMENT_END_DATE]: 'Enrollment end date',
  [DynamicVariableKey.EXTERNAL_ENROLLMENT_START_DATE]:
    'External enrollment start date',
  [DynamicVariableKey.EXTERNAL_ENROLLMENT_END_DATE]:
    'External enrollment end date',
  [DynamicVariableKey.NEXT_ENROLLMENT_START_DATE]: 'Next enrollment start date',
  [DynamicVariableKey.NEXT_ENROLLMENT_END_DATE]: 'Next enrollment end date',
  [DynamicVariableKey.NEXT_OFFER_START_DATE]: 'Next offer start date',
  [DynamicVariableKey.CURRENT_PURCHASE_DATE]: 'Current purchase date',
  [DynamicVariableKey.CURRENT_CONTRIBUTION_PERCENTAGE]:
    'Current contribution percentage',
  [DynamicVariableKey.NEXT_CONTRIBUTION_PERCENTAGE]:
    'Next contribution percentage',
  [DynamicVariableKey.PLAN_MAX]: 'Max percentage for the plan',
  [DynamicVariableKey.CASHLESS_PARTICIPATION_TEXT_ON]:
    'Cashless Participation is ON - translated',
  [DynamicVariableKey.CASHLESS_PARTICIPATION_TEXT_OFF]:
    'Cashless Participation is OFF - translated',
  [DynamicVariableKey.CASHLESS_PARTICIPATION_TEXT_ON_OR_OFF]:
    'Cashless Participation is ON or OFF - translated',
  [DynamicVariableKey.ELIGIBLE_PAY_NAME_TEXT]: 'Eligible pay name - translated',
  [DynamicVariableKey.GENERAL_EMPLOYEE_STOCK_PURCHASE_PLAN_PRODUCT_NAME_LONG]:
    'The full name of the product *type*, even if the product *name* is different - this will probably be "Employee Stock Purchase Plan" or its translation in all cases.',
  [DynamicVariableKey.GENERAL_EMPLOYEE_STOCK_PURCHASE_PLAN_PRODUCT_NAME_SHORT]:
    'The abbreviated name of the product *type*, even if the product *name* is different - this will probably be "ESPP" or its translation in all cases.',
  [DynamicVariableKey.DESIGNATED_SALE_PRICE_DSP_RATE]:
    'The designated sale price percent for a DSP based plan',
  [DynamicVariableKey.MAX_CASHLESS_CONTRIBUTION_PERCENT]:
    "The maximum percent of a participant's compensation they are allowed to contribute in order to qualify for Cashless",
  [DynamicVariableKey.MIN_CASHLESS_CONTRIBUTION_PERCENT]:
    "The minimum percent of a participant's compensation they must contribute in order to qualify for Cashless",
  [DynamicVariableKey.MAX_ESPP_CONTRIBUTION_PERCENT]:
    "The maximum percent of a participant's compensation they are allowed to contribute for the ESPP",
  [DynamicVariableKey.MIN_ESPP_CONTRIBUTION_PERCENT]:
    "The minimum percent of a participant's compensation they can contribute in the ESPP",
  [DynamicVariableKey.CASHLESS_MAX_BOOST_PERCENT]:
    "The amount that a user's contribution is boosted to if they use cashless. E.g. in some plans, someone can enroll in cashless with 1% contribution and cashless boosts them to 10%, but if they contribute 10% then they can't use cashless.",
  [DynamicVariableKey.CASHLESS_DIFF_BETWEEN_MAX_BOOST_AND_MIN_PERCENT]:
    "The difference between the amount that a user's contribution is boosted to if they use cashless and the minimum percent of a participant's compensation they must contribute in order to qualify for Cashless",
  [DynamicVariableKey.PAY_FREQUENCY_WEEKLY_TEXT]: 'Once a week - translated',
  [DynamicVariableKey.PAY_FREQUENCY_BIWEEKLY_TEXT]:
    'Every two weeks - translated',
  [DynamicVariableKey.PAY_FREQUENCY_MONTHLY_TEXT]: 'Once a month - translated',
  [DynamicVariableKey.PAY_FREQUENCY_SEMIMONTHLY_TEXT]:
    'Twice a month - translated',
  [DynamicVariableKey.PAY_FREQUENCY_LUNAR_TEXT]:
    'Every four weeks - translated',
  [DynamicVariableKey.PAY_FREQUENCY_TEXT]: 'Pay frequency - translated',
  [DynamicVariableKey.HOURS_WORKED_PER_WEEK]: 'Pay frequency in hours',
  [DynamicVariableKey.PLAN_DISCOUNT_PERCENT]:
    'Plan discount percent (without the percent symbol)',
  [DynamicVariableKey.PLAN_MIN_HOURS_WORKED_FOR_ELIGIBILITY]:
    'The mininum hours an employee must work per week to qualify for the ESPP (this does not exist for all plans!)',
  [DynamicVariableKey.PLAN_AFTER_DISCOUNT_PERCENT]:
    '100 minus the plan discount percent (without the percent symbol)',
  [DynamicVariableKey.PRODUCT_NAME_LONG]:
    'The unabbreviated form of the product name, e.g. Employee Stock Purchase Plan',
  [DynamicVariableKey.MONTHS_IN_PURCHASE_PERIOD]:
    'The number of months in a purchase period.',
  [DynamicVariableKey.MONTHS_IN_OFFERING_PERIOD]:
    'The number of months in an offering period.',
  [DynamicVariableKey.PURCHASES_IN_OFFERING_PERIOD]:
    'The number of purchases in an offering period.',
  [DynamicVariableKey.OFFERING_PERIOD_START_FULL_DATE_NO_YEAR]:
    'Start date of offering period as e.g. "January 12"',
  [DynamicVariableKey.OFFERING_PERIOD_END_FULL_DATE_NO_YEAR]:
    'End date of offering period as e.g. "January 12"',
  [DynamicVariableKey.OFFERING_PERIOD_START_FULL_DATE]:
    'Start date of offering period as e.g. "January 12, 2022"',
  [DynamicVariableKey.OFFERING_PERIOD_END_FULL_DATE]:
    'End date of offering period as e.g. "January 12, 2022"',
  [DynamicVariableKey.ENROLLMENT_START_FULL_DATE_NO_YEAR]:
    'Start date of enrollment period as e.g. "January 12"',
  [DynamicVariableKey.ENROLLMENT_END_FULL_DATE_NO_YEAR]:
    'End date of enrollment period as e.g. "January 12"',
  [DynamicVariableKey.ENROLLMENT_EOI_DEADLINE]:
    'Deadline date for EOI Form within enrollment period, calculated based on the offset in days after the enrollment period start date',
  [DynamicVariableKey.PURCHASE_PERIOD_START_FULL_DATE_NO_YEAR]:
    'Start date of purchase period as e.g. "January 12"',
  [DynamicVariableKey.PURCHASE_PERIOD_END_FULL_DATE_NO_YEAR]:
    'End date of purchase period as e.g. "January 12',
  [DynamicVariableKey.FUTURE_OFFERING_PERIOD_START_FULL_DATE_NO_YEAR]:
    'Start date of *future* offering period as e.g. "January 12"',
  [DynamicVariableKey.FUTURE_OFFERING_PERIOD_END_FULL_DATE_NO_YEAR]:
    'End date of *future* offering period as e.g. "January 12"',
  [DynamicVariableKey.FUTURE_OFFERING_PERIOD_START_FULL_DATE]:
    'Start date of *future* offering period as e.g. "January 12, 2022"',
  [DynamicVariableKey.FUTURE_OFFERING_PERIOD_END_FULL_DATE]:
    'End date of *future* offering period as e.g. "January 12, 2022"',
  [DynamicVariableKey.FUTURE_ENROLLMENT_END_DATE_TIME_ZONE]:
    'The time zone of the end date of the future enrollment period. (It depends on the date because e.g. it may be CDT now, but CST when enrollment ends)',
  [DynamicVariableKey.PURCHASE_DATE_FULL_DATE]:
    'The upcoming purchase date for the *current* purchase period as e.g. "January 12, 2022"',
  [DynamicVariableKey.PURCHASE_DATE_FULL_DATE_NO_YEAR]:
    'The upcoming purchase date for the *current* purchase period as e.g. "January 12"',
  [DynamicVariableKey.FUTURE_PURCHASE_DATE_FULL_DATE]:
    'The purchase date for the *future* purchase period (i.e. in most cases this will not be the immediately upcoming purchase), as e.g. "January 12, 2022"',
  [DynamicVariableKey.FUTURE_PURCHASE_DATE_FULL_DATE_NO_YEAR]:
    'The purchase date for the *future* purchase period (i.e. in most cases this will not be the immediately upcoming purchase), as e.g. "January 12"',
  [DynamicVariableKey.FUTURE_PURCHASE_PERIOD_START_FULL_DATE_NO_YEAR]:
    'Start date of the *future* purchase period (i.e. in most cases this will not be the immediately upcoming purchase), as e.g. "January 12"',
  [DynamicVariableKey.FUTURE_PURCHASE_PERIOD_END_FULL_DATE_NO_YEAR]:
    'End date of the *future* purchase period (i.e. in most cases this will not be the immediately upcoming purchase), as e.g. "January 12',
  [DynamicVariableKey.POTENTIAL_FUTURE_WITHDRAWN_AT_DATE_FULL_DATE]:
    'The date when the user will be withdrawn or the closest purchase date',
  [DynamicVariableKey.USER_FIRST_NAME]: "The user's first name",
  [DynamicVariableKey.LAST_DAY_TO_WITHDRAW_FROM_CURRENT]:
    'The last date when a withdrawal for a current period is available',
  [DynamicVariableKey.CONTRIBUTION_CHANGE_DEADLINE]:
    'The last date a contribution change is available',
  [DynamicVariableKey.CONTRIBUTION_CHANGE_COUNT_RESETS_PER_PURCHASE_OR_PER_OFFERING]:
    'Whether the number of changes a user can make to their contribution resets per purchase period or per offering period.',
  [DynamicVariableKey.SUSPENSION_COUNT_RESETS_PER_PURCHASE_OR_PER_OFFERING]:
    'Whether the number of suspensions a user can do resets per purchase period or per offering period.',
  [DynamicVariableKey.CONTRIBUTION_CHANGES_REMAINING]:
    'Remaining number of contribution changes',
  [DynamicVariableKey.CONTRIBUTION_CHANGES_FREQUENCY]:
    'Available number of contribution changes',
  [DynamicVariableKey.CONTRIBUTION_INCREASES_REMAINING]:
    'Remaining number of contribution increases',
  [DynamicVariableKey.CONTRIBUTION_INCREASES_FREQUENCY]:
    'Available number of contribution increases per period',
  [DynamicVariableKey.CONTRIBUTION_DECREASES_REMAINING]:
    'Remaining number of contribution decreases',
  [DynamicVariableKey.CONTRIBUTION_DECREASES_FREQUENCY]:
    'Available number of contribution decreases per period',
  [DynamicVariableKey.TRADE_RESTRICTION_WINDOW_START_DATETIME]:
    'The date, time, and time zone of the trade restriction window start',
  [DynamicVariableKey.TRADE_RESTRICTION_WINDOW_END_DATETIME]:
    'The date, time, and time zone of the trade restriction window end',
  [DynamicVariableKey.RESET_CHANGE_WINDOW_END_DATETIME]:
    'The date, time, and time zone of the reset change window end',
  [DynamicVariableKey.CASHLESS_FEE_PERCENT]:
    'The percent value of the fee rate (without the % sign), i.e. "12" means a 12% fee',
  [DynamicVariableKey.LEARN_MORE_LINK_TEXT]:
    'The default link text for resource library resources, etc.',
  [DynamicVariableKey.PLAN_TIME_ZONE]:
    'The time zone that the plan operates in. (E.g. all of a US plan might operate on EST even for California employees).',
  [DynamicVariableKey.FIRST_MINUTE_OF_DAY]:
    'The first minute of the day, which varies by region. (E.g. 0h00)',
  [DynamicVariableKey.LAST_MINUTE_OF_DAY]:
    'The last minute of the day, which varies by region. (E.g. 11:59PM/23h59)',
  [DynamicVariableKey.CURRENCY_EXCHANGE_FX_RATE]:
    "Exchange rate between the user's currency and USD",
  [DynamicVariableKey.LOGIN_EMAIL]: 'The email the user must use to log in',
  [DynamicVariableKey.STOCK_ADMIN_LOGIN_URL]:
    'The login page URL for stock admin',
  [DynamicVariableKey.STOCK_ADMIN_TERMS_URL]:
    'The Terms and Conditions page URL for stock admin',
  [DynamicVariableKey.STOCK_ADMIN_TAX_URL]:
    'The Tax Information page URL for stock admin',
  [DynamicVariableKey.STOCK_ADMIN_POLICY_URL]:
    'The Privacy policy page URL for stock admin',
  [DynamicVariableKey.WEALTH_MANAGEMENT_FIRM_NAME]:
    'The wealth management firm name',
  [DynamicVariableKey.FIRST_EVER_OFFERING_PERIOD_START_DATE]:
    'The first ever offering period start date for tooltip in the account summary & status page.',
  [DynamicVariableKey.REFERRAL_REWARD_OFFER_VALUE]:
    'The value of the referral reward in user currency',
  [DynamicVariableKey.RETURN_ON_INVESTMENT_PERCENT]:
    'The value for the % return on investment based on the plan discount',
  [DynamicVariableKey.EOI_FORM_URL]: 'The Expression of Interest form url',
  [DynamicVariableKey.EMPLOYEE_COMPANY_ID]: 'The company employee ID',
  [DynamicVariableKey.PRIMARY_COLOR]:
    'The primary (issuer) color used on the site',
  [DynamicVariableKey.SECONDARY_COLOR]: 'The secondary color used on the site',
};

interface IInsertDynamicVariables {
  contentTypeName: string;
  customMapping?: Record<string, string> | undefined;
  ignoredCustomMapping?: string[];
  customDynamicConditionals?: Record<string, boolean> | undefined;
  ignoredCustomDynamicConditionals?: string[];
}

export const useParseDynamicContent = ({
  contentTypeName,
  customMapping,
  ignoredCustomMapping,
  customDynamicConditionals,
  ignoredCustomDynamicConditionals,
}: IInsertDynamicVariables) => {
  const {
    adminName,
    companyRegisteredName,
    contactEmail,
    displayName,
    employerPlanName,
    productName,
    stockPlanAdmin,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    first_offer_start_date,
    externalEnrollmentSiteProvider,
    externalEnrollmentSiteUrl,
    isPlanInSuspensionState,
    planNameByLang,
    productNameLongByLang,
    productNameShortByLang,
    generalProductNameLongByLang,
    generalProductNameShortByLang,
    isEntireIssuerEsppOnly,
  } = useSelector(issuerSelector);
  const {
    calculator,
    changesDuringOffering,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    name: { first: firstName, login_email },
    referralRewardInUserCurrencyWholeUnits,
    tradeRestrictionWindowStartDatetime,
    tradeRestrictionWindowEndDatetime,
    employeeCompanyId,
    futureChangePeriodType,
    effectiveDates,
  } = useSelector(userSelector);
  const sellToCoverEnabled = useIsSellToCoverEnabled();
  const { isUsa: isUserCountryUsa } = useGetUserLocation();
  const theme = useGetTheme();
  const currencyConversion = useCurrencyConversion();
  const { formatLuxonDateTime, formatIsoTime } = useDateFormatter();
  const { formatCurrencyFromWholeUnits } = useCurrencyFormatter();
  const { locale } = useLocale();
  const jurisdictionData = useGetJurisdictionData();
  const enrollmentPlan = useGetEnrollmentPlan();
  const isEsppOnlyPlan = usePlanIsEsppOnly();
  const isCashlessMatchPlan = useIsCashlessMatchPlan();
  const planDetails = usePlanDetails();
  const periodsDates = useGetPeriodsDatesInfo();
  const { userStateInClosestPeriodOpOrPp, userStateInFuturePeriodOpOrPp } =
    useGetUserStatesInPeriods();

  const { enrollmentEoiDeadlineDate } = useGetExpressionOfInterestInfo();

  const issuerShortName = useGetIssuerShortname() ?? '';
  const planName = planNameByLang[locale] ?? employerPlanName;
  const productNameShort = productNameShortByLang[locale] ?? productName;
  const productNameLong = productNameLongByLang[locale] ?? productName;
  const generalProductNameShort =
    generalProductNameShortByLang[locale] ?? productName;
  const generalProductNameLong =
    generalProductNameLongByLang[locale] ?? productName;

  const formatTime = useCallback(
    (timeIn24hFormat: string) => {
      const time = DateTime.fromFormat(timeIn24hFormat, 'T', { setZone: true });
      return formatToLocale(time, DateFormat.TIME, locale) ?? '-';
    },
    [locale],
  );

  const planMaxFormatted = (planDetails?.enrollMinMax?.max ?? 0).toString();

  const globalTextVariables = useContext(GlobalTextVariablesContext);
  const eligiblePayNameText =
    globalTextVariables.eligiblePayName ?? 'eligible pay name';
  const cashlessParticipationOnText =
    globalTextVariables.cashlessParticipationOn ?? 'ON';
  const cashlessParticipationOffText =
    globalTextVariables.cashlessParticipationOff ?? 'OFF';
  const cashlessParticipationOnOrOffText =
    userStateInClosestPeriodOpOrPp.enrolledCashless
      ? cashlessParticipationOnText
      : cashlessParticipationOffText;
  const dsp = planDetails?.employerMaxSellPricePercent
    ? String(planDetails?.employerMaxSellPricePercent)
    : '-';
  const payFrequencyCalculatorWeeklyText =
    globalTextVariables.payFrequencyCalculatorWeekly ?? '';
  const payFrequencyCalculatorBiWeeklyText =
    globalTextVariables.payFrequencyCalculatorBiweekly ?? '';
  const payFrequencyCalculatorMonthlyText =
    globalTextVariables.payFrequencyCalculatorMonthly ?? '';
  const payFrequencyCalculatorSemiMonthlyText =
    globalTextVariables.payFrequencyCalculatorSemimonthly ?? '';
  const payFrequencyCalculatorLunarText =
    globalTextVariables.payFrequencyCalculatorLunar ?? '';
  const payFrequencyText = match(calculator.frequency)
    .with('weekly', () => payFrequencyCalculatorWeeklyText)
    .with('biweekly', () => payFrequencyCalculatorBiWeeklyText)
    .with('monthly', () => payFrequencyCalculatorMonthlyText)
    .with('semimonthly', () => payFrequencyCalculatorSemiMonthlyText)
    .with('lunar', () => payFrequencyCalculatorLunarText)
    .otherwise(() => '')
    .toLowerCase();
  const payFrequencyInHours = calculator.scheduledWeeklyHours?.toString() ?? '';

  const currencyCodesInCurrencyConversion =
    currencyConversion?.symbol?.split('/');
  let currencyConversionText = '';
  if (currencyCodesInCurrencyConversion.length < 2) {
    console.error(
      `Currency conversion symbols have an unexpected format: ${
        currencyConversion?.symbol ?? ''
      }`,
    );
  } else {
    const sourceCurrencyText = `1 ${currencyCodesInCurrencyConversion[0]}`;
    const targetCurrencyText = `${currencyConversion?.rate} ${currencyCodesInCurrencyConversion[1]}`;
    const defaultConversionText = `${sourceCurrencyText} : ${targetCurrencyText}`;
    currencyConversionText =
      globalTextVariables.currencyExchangeFxRatePlaceholder
        ? replaceVariablesInString(
          globalTextVariables.currencyExchangeFxRatePlaceholder,
          {
            [globalTextVariables.customDynamicVariableSourceCurrency]:
                sourceCurrencyText,
            [globalTextVariables.customDynamicVariableTargetCurrency]:
                targetCurrencyText,
          },
        ) ?? defaultConversionText
        : defaultConversionText;
  }

  const returnOnInvestmentPercent = Math.trunc(
    (1 / (1 - (planDetails?.discount ?? 0) / 100) - 1) * 100,
  );

  const dynamicVariableKeyToValueMapping: {
    [key in DynamicVariableKey]: string | number;
  } = useMemo(
    () => ({
      [DynamicVariableKey.ISSUER_DISPLAY_NAME]: displayName,
      [DynamicVariableKey.STOCK_ADMIN]: adminName,
      [DynamicVariableKey.EXTERNAL_ENROLLMENT_PROVIDER_NAME]:
        externalEnrollmentSiteProvider ?? adminName,
      [DynamicVariableKey.EXTERNAL_ENROLLMENT_URL]:
        externalEnrollmentSiteUrl ?? stockPlanAdmin?.login_url,
      [DynamicVariableKey.ISSUER_LEGAL_REGISTERED_NAME]: companyRegisteredName,
      [DynamicVariableKey.PRODUCT_NAME]: productNameShort,
      // This should fall back to a generic email. We saw one case where fetching
      // the contact email failed due to a bug, and the user rage clicked the contact
      // link because it wasn't working:
      [DynamicVariableKey.CONTACT_EMAIL]: contactEmail || 'support@espp.com',
      [DynamicVariableKey.ISSUER_SHORT_NAME]: issuerShortName,
      [DynamicVariableKey.ISSUER_PLAN_NAME]: planName,
      [DynamicVariableKey.ENROLLMENT_START_DATE]: formatLuxonDateTime(
        periodsDates.closestEnrollmentDates.start,
      ),
      [DynamicVariableKey.ENROLLMENT_END_DATE]: formatLuxonDateTime(
        periodsDates.closestEnrollmentDates.end,
      ),
      [DynamicVariableKey.ENROLLMENT_EOI_DEADLINE]: formatLuxonDateTime(
        enrollmentEoiDeadlineDate,
      ),
      [DynamicVariableKey.EXTERNAL_ENROLLMENT_START_DATE]:
        periodsDates.closestExternalEnrollmentDates
          ? formatLuxonDateTime(
            periodsDates.closestExternalEnrollmentDates.start,
          )
          : '-',
      [DynamicVariableKey.EXTERNAL_ENROLLMENT_END_DATE]:
        periodsDates.closestExternalEnrollmentDates
          ? formatLuxonDateTime(periodsDates.closestExternalEnrollmentDates.end)
          : '-',
      [DynamicVariableKey.NEXT_ENROLLMENT_START_DATE]: formatLuxonDateTime(
        periodsDates.futureEnrollmentDates.start,
      ),
      [DynamicVariableKey.NEXT_ENROLLMENT_END_DATE]: formatLuxonDateTime(
        periodsDates.futureEnrollmentDates.end,
      ),
      [DynamicVariableKey.NEXT_OFFER_START_DATE]: formatLuxonDateTime(
        periodsDates.futureOfferingDates.start,
      ),
      [DynamicVariableKey.CURRENT_PURCHASE_DATE]: periodsDates
        .currentPurchaseDates?.purchaseDate
        ? formatLuxonDateTime(periodsDates.currentPurchaseDates.purchaseDate)
        : '-',
      [DynamicVariableKey.CURRENT_CONTRIBUTION_PERCENTAGE]: (
        userStateInClosestPeriodOpOrPp.contributionPercentage ?? 0
      ).toString(),
      [DynamicVariableKey.NEXT_CONTRIBUTION_PERCENTAGE]: (
        userStateInFuturePeriodOpOrPp.contributionPercentage ?? 0
      ).toString(),
      [DynamicVariableKey.PLAN_MAX]: planMaxFormatted,
      [DynamicVariableKey.CASHLESS_PARTICIPATION]:
        planDetails?.cashlessMethodology === 'MATCH'
          ? DataConstants.CASHLESS_MATCH_CONST
          : DataConstants.CASHLESS_PARTICIPATION_CONST,
      [DynamicVariableKey.CASHLESS_PARTICIPATION_TEXT_ON]:
        cashlessParticipationOnText,
      [DynamicVariableKey.CASHLESS_PARTICIPATION_TEXT_OFF]:
        cashlessParticipationOffText,
      [DynamicVariableKey.CASHLESS_PARTICIPATION_TEXT_ON_OR_OFF]:
        cashlessParticipationOnOrOffText,
      [DynamicVariableKey.ELIGIBLE_PAY_NAME_TEXT]: eligiblePayNameText,
      [DynamicVariableKey.RESET_CHANGE_WINDOW_END_DATETIME]:
        periodsDates.resetChangeWindowDates
          ? formatLuxonDateTime(
            periodsDates.resetChangeWindowDates.end,
            DateFormat.LONG,
          )
          : '-',
      [DynamicVariableKey.DESIGNATED_SALE_PRICE_DSP_RATE]: String(
        Number(dsp) + 100,
      ),
      [DynamicVariableKey.MAX_CASHLESS_CONTRIBUTION_PERCENT]: String(
        planDetails?.cashlessCapPercent ?? '-',
      ),
      [DynamicVariableKey.MIN_CASHLESS_CONTRIBUTION_PERCENT]: String(
        planDetails?.enrollMinMax?.min ?? '-',
      ),
      [DynamicVariableKey.MAX_ESPP_CONTRIBUTION_PERCENT]:
        planDetails?.enrollMinMax?.max ?? '-',
      [DynamicVariableKey.MIN_ESPP_CONTRIBUTION_PERCENT]:
        planDetails?.enrollMinMax?.min ?? '-',
      [DynamicVariableKey.CASHLESS_MAX_BOOST_PERCENT]: String(
        planDetails?.cashless_percent_contribution_to_boost_to ?? '-',
      ),
      [DynamicVariableKey.CASHLESS_DIFF_BETWEEN_MAX_BOOST_AND_MIN_PERCENT]:
        planDetails?.cashless_percent_contribution_to_boost_to &&
        planDetails?.enrollMinMax?.min
          ? planDetails.cashless_percent_contribution_to_boost_to -
            planDetails.enrollMinMax.min
          : '-',
      [DynamicVariableKey.PAY_FREQUENCY_WEEKLY_TEXT]:
        payFrequencyCalculatorWeeklyText,
      [DynamicVariableKey.PAY_FREQUENCY_BIWEEKLY_TEXT]:
        payFrequencyCalculatorBiWeeklyText,
      [DynamicVariableKey.PAY_FREQUENCY_MONTHLY_TEXT]:
        payFrequencyCalculatorMonthlyText,
      [DynamicVariableKey.PAY_FREQUENCY_SEMIMONTHLY_TEXT]:
        payFrequencyCalculatorSemiMonthlyText,
      [DynamicVariableKey.PAY_FREQUENCY_LUNAR_TEXT]:
        payFrequencyCalculatorLunarText,
      [DynamicVariableKey.PAY_FREQUENCY_TEXT]: payFrequencyText,
      [DynamicVariableKey.HOURS_WORKED_PER_WEEK]: payFrequencyInHours,
      [DynamicVariableKey.PLAN_DISCOUNT_PERCENT]:
        planDetails?.discount?.toString() ?? '-',
      [DynamicVariableKey.PLAN_AFTER_DISCOUNT_PERCENT]: planDetails?.discount
        ? String(100 - planDetails.discount)
        : '-',
      [DynamicVariableKey.PLAN_MIN_HOURS_WORKED_FOR_ELIGIBILITY]: String(
        planDetails?.min_weekly_working_hours_for_eligibility ?? '-',
      ),
      [DynamicVariableKey.PRODUCT_NAME_LONG]: productNameLong,
      [DynamicVariableKey.GENERAL_EMPLOYEE_STOCK_PURCHASE_PLAN_PRODUCT_NAME_LONG]:
        generalProductNameLong,
      [DynamicVariableKey.GENERAL_EMPLOYEE_STOCK_PURCHASE_PLAN_PRODUCT_NAME_SHORT]:
        generalProductNameShort,
      [DynamicVariableKey.MONTHS_IN_PURCHASE_PERIOD]:
        planDetails?.monthsInPurchasePeriod?.toString() ?? '-',
      [DynamicVariableKey.MONTHS_IN_OFFERING_PERIOD]:
        planDetails?.monthsInOfferingPeriod?.toString() ?? '-',
      [DynamicVariableKey.PURCHASES_IN_OFFERING_PERIOD]:
        planDetails?.monthsInPurchasePeriod &&
        planDetails?.monthsInOfferingPeriod
          ? (
            planDetails.monthsInOfferingPeriod /
              planDetails.monthsInPurchasePeriod
          ).toString()
          : '-',
      [DynamicVariableKey.OFFERING_PERIOD_START_FULL_DATE_NO_YEAR]:
        periodsDates.currentOfferingDates
          ? formatLuxonDateTime(
            periodsDates.currentOfferingDates.start,
            DateFormat.LONG_NO_YEAR,
          )
          : '-',
      [DynamicVariableKey.OFFERING_PERIOD_END_FULL_DATE_NO_YEAR]:
        periodsDates.currentOfferingDates
          ? formatLuxonDateTime(
            periodsDates.currentOfferingDates.end,
            DateFormat.LONG_NO_YEAR,
          )
          : '-',
      [DynamicVariableKey.OFFERING_PERIOD_START_FULL_DATE]:
        periodsDates.currentOfferingDates
          ? formatLuxonDateTime(
            periodsDates.currentOfferingDates.start,
            DateFormat.LONG,
          )
          : '-',
      [DynamicVariableKey.OFFERING_PERIOD_END_FULL_DATE]:
        periodsDates.currentOfferingDates
          ? formatLuxonDateTime(
            periodsDates.currentOfferingDates.end,
            DateFormat.LONG,
          )
          : '-',
      [DynamicVariableKey.FUTURE_OFFERING_PERIOD_START_FULL_DATE_NO_YEAR]:
        periodsDates.futureOfferingDates
          ? formatLuxonDateTime(
            periodsDates.futureOfferingDates.start,
            DateFormat.LONG_NO_YEAR,
          )
          : '-',
      [DynamicVariableKey.FUTURE_OFFERING_PERIOD_END_FULL_DATE_NO_YEAR]:
        periodsDates.futureOfferingDates
          ? formatLuxonDateTime(
            periodsDates.futureOfferingDates.end,
            DateFormat.LONG_NO_YEAR,
          )
          : '-',
      [DynamicVariableKey.FUTURE_OFFERING_PERIOD_START_FULL_DATE]:
        periodsDates.futureOfferingDates
          ? formatLuxonDateTime(
            periodsDates.futureOfferingDates.start,
            DateFormat.LONG,
          )
          : '-',
      [DynamicVariableKey.FUTURE_OFFERING_PERIOD_END_FULL_DATE]:
        periodsDates.futureOfferingDates
          ? formatLuxonDateTime(
            periodsDates.futureOfferingDates.end,
            DateFormat.LONG,
          )
          : '-',
      [DynamicVariableKey.FUTURE_ENROLLMENT_END_DATE_TIME_ZONE]:
        jurisdictionData?.future_enrollment_end_date_time_zone_display ?? '-',
      [DynamicVariableKey.ENROLLMENT_START_FULL_DATE_NO_YEAR]:
        formatLuxonDateTime(
          periodsDates.closestEnrollmentDates.start,
          DateFormat.LONG_NO_YEAR,
        ),
      [DynamicVariableKey.ENROLLMENT_END_FULL_DATE_NO_YEAR]:
        formatLuxonDateTime(
          periodsDates.closestEnrollmentDates.end,
          DateFormat.LONG_NO_YEAR,
        ),
      [DynamicVariableKey.PURCHASE_PERIOD_START_FULL_DATE_NO_YEAR]:
        planDetails?.purchaseStartDate
          ? formatLuxonDateTime(
            periodsDates.closestPurchaseDates.start,
            DateFormat.LONG_NO_YEAR,
          )
          : '-',
      [DynamicVariableKey.PURCHASE_PERIOD_END_FULL_DATE_NO_YEAR]:
        planDetails?.purchaseStartDate
          ? formatLuxonDateTime(
            periodsDates.closestPurchaseDates.end,
            DateFormat.LONG_NO_YEAR,
          )
          : '-',
      [DynamicVariableKey.TRADE_RESTRICTION_WINDOW_START_DATETIME]:
        tradeRestrictionWindowStartDatetime
          ? formatIsoTime(
            tradeRestrictionWindowStartDatetime,
            DateFormat.LONG_DATE_TIME_AND_TIME_ZONE,
          )
          : '-',
      [DynamicVariableKey.TRADE_RESTRICTION_WINDOW_END_DATETIME]:
        tradeRestrictionWindowEndDatetime
          ? formatIsoTime(
            tradeRestrictionWindowEndDatetime,
            DateFormat.LONG_DATE_TIME_AND_TIME_ZONE,
          )
          : '-',
      [DynamicVariableKey.PURCHASE_DATE_FULL_DATE]: formatLuxonDateTime(
        periodsDates.closestPurchaseDates.purchaseDate,
        DateFormat.LONG,
      ),
      [DynamicVariableKey.PURCHASE_DATE_FULL_DATE_NO_YEAR]: formatLuxonDateTime(
        periodsDates.closestPurchaseDates.purchaseDate,
        DateFormat.LONG_NO_YEAR,
      ),
      [DynamicVariableKey.FUTURE_PURCHASE_DATE_FULL_DATE]: formatLuxonDateTime(
        periodsDates.futurePurchaseDates.purchaseDate,
        DateFormat.LONG,
      ),
      [DynamicVariableKey.FUTURE_PURCHASE_DATE_FULL_DATE_NO_YEAR]:
        formatLuxonDateTime(
          periodsDates.futurePurchaseDates.purchaseDate,
          DateFormat.LONG_NO_YEAR,
        ),
      [DynamicVariableKey.POTENTIAL_FUTURE_WITHDRAWN_AT_DATE_FULL_DATE]:
        formatLuxonDateTime(
          effectiveDates.future_date_to_be_withdrawn_at
            ? DateTime.fromISO(effectiveDates.future_date_to_be_withdrawn_at, {
              setZone: true,
            })
            : periodsDates.closestPurchaseDates.purchaseDate,
          DateFormat.LONG,
        ),
      [DynamicVariableKey.FUTURE_PURCHASE_PERIOD_START_FULL_DATE_NO_YEAR]:
        planDetails?.purchaseStartDate
          ? formatLuxonDateTime(
            periodsDates.futurePurchaseDates.start,
            DateFormat.LONG,
          )
          : '-',
      [DynamicVariableKey.FUTURE_PURCHASE_PERIOD_END_FULL_DATE_NO_YEAR]:
        planDetails?.purchaseStartDate
          ? formatLuxonDateTime(
            periodsDates.futurePurchaseDates.end,
            DateFormat.LONG_NO_YEAR,
          )
          : '-',
      [DynamicVariableKey.CONTRIBUTION_CHANGE_DEADLINE]: periodsDates
        .currentPurchaseDates?.lastDayToMakeChanges
        ? formatLuxonDateTime(
          periodsDates.currentPurchaseDates.lastDayToMakeChanges,
        )
        : '-',
      [DynamicVariableKey.LAST_DAY_TO_WITHDRAW_FROM_CURRENT]: periodsDates
        .currentPurchaseDates?.lastDayToWithdraw
        ? formatLuxonDateTime(
          periodsDates.currentPurchaseDates.lastDayToWithdraw,
        )
        : '-',
      [DynamicVariableKey.CONTRIBUTION_CHANGE_COUNT_RESETS_PER_PURCHASE_OR_PER_OFFERING]:
        (planDetails?.contribution_change_limit_period ===
        ChangeLimitPeriod.PURCHASE_PERIOD
          ? globalTextVariables?.perPurchasePeriod
          : globalTextVariables?.perOfferingPeriod) ?? '-',
      [DynamicVariableKey.SUSPENSION_COUNT_RESETS_PER_PURCHASE_OR_PER_OFFERING]:
        (planDetails?.contribution_change_limit_period ===
        ChangeLimitPeriod.PURCHASE_PERIOD
          ? globalTextVariables?.perPurchasePeriod
          : globalTextVariables?.perOfferingPeriod) ?? '',
      [DynamicVariableKey.CONTRIBUTION_CHANGES_REMAINING]:
        changesDuringOffering.remainingChangesAnyDirection ?? '-',
      [DynamicVariableKey.CONTRIBUTION_CHANGES_FREQUENCY]:
        changesDuringOffering.changesFrequencyAnyDirection ?? '-',
      [DynamicVariableKey.CONTRIBUTION_INCREASES_REMAINING]:
        changesDuringOffering.remainingChangesUp ?? '-',
      [DynamicVariableKey.CONTRIBUTION_INCREASES_FREQUENCY]:
        changesDuringOffering.changesFrequencyUp ?? '-',
      [DynamicVariableKey.CONTRIBUTION_DECREASES_REMAINING]:
        changesDuringOffering.remainingChangesDown ?? '-',
      [DynamicVariableKey.CONTRIBUTION_DECREASES_FREQUENCY]:
        changesDuringOffering.changesFrequencyDown ?? '-',
      [DynamicVariableKey.USER_FIRST_NAME]: firstName ?? '',
      [DynamicVariableKey.CASHLESS_FEE_PERCENT]:
        planDetails?.cashless_amount_fee_percentage?.toString() ?? '-',
      [DynamicVariableKey.LEARN_MORE_LINK_TEXT]:
        globalTextVariables?.learnMoreLinkText ?? '-',
      [DynamicVariableKey.PLAN_TIME_ZONE]:
        jurisdictionData?.time_zone_display ?? '-',
      [DynamicVariableKey.FIRST_MINUTE_OF_DAY]: formatTime('00:00'),
      [DynamicVariableKey.LAST_MINUTE_OF_DAY]: formatTime('23:59'),
      [DynamicVariableKey.CURRENCY_EXCHANGE_FX_RATE]: currencyConversionText,
      [DynamicVariableKey.LOGIN_EMAIL]: login_email ?? '-',
      [DynamicVariableKey.STOCK_ADMIN_LOGIN_URL]: stockPlanAdmin?.login_url,
      [DynamicVariableKey.STOCK_ADMIN_TAX_URL]:
        stockPlanAdmin?.tax_information_url,
      [DynamicVariableKey.STOCK_ADMIN_TERMS_URL]:
        stockPlanAdmin?.terms_and_conditions_url,
      [DynamicVariableKey.STOCK_ADMIN_POLICY_URL]:
        stockPlanAdmin?.privacy_policy_url,
      [DynamicVariableKey.WEALTH_MANAGEMENT_FIRM_NAME]:
        stockPlanAdmin?.wealth_management_firm_name,
      [DynamicVariableKey.FIRST_EVER_OFFERING_PERIOD_START_DATE]:
        first_offer_start_date,
      [DynamicVariableKey.REFERRAL_REWARD_OFFER_VALUE]:
        formatCurrencyFromWholeUnits(referralRewardInUserCurrencyWholeUnits),
      [DynamicVariableKey.RETURN_ON_INVESTMENT_PERCENT]:
        returnOnInvestmentPercent,
      [DynamicVariableKey.EOI_FORM_URL]: 'europe',
      [DynamicVariableKey.EMPLOYEE_COMPANY_ID]: employeeCompanyId ?? '',
      [DynamicVariableKey.PRIMARY_COLOR]: theme.colors.primary,
      [DynamicVariableKey.SECONDARY_COLOR]: theme.colors.secondary,
    }),
    [
      displayName,
      adminName,
      planName,
      productNameLong,
      productNameShort,
      generalProductNameLong,
      generalProductNameShort,
      externalEnrollmentSiteProvider,
      externalEnrollmentSiteUrl,
      stockPlanAdmin?.login_url,
      stockPlanAdmin?.tax_information_url,
      stockPlanAdmin?.terms_and_conditions_url,
      stockPlanAdmin?.privacy_policy_url,
      stockPlanAdmin?.wealth_management_firm_name,
      companyRegisteredName,
      contactEmail,
      issuerShortName,
      formatLuxonDateTime,
      formatIsoTime,
      enrollmentEoiDeadlineDate,
      planMaxFormatted,
      planDetails?.cashlessMethodology,
      planDetails?.cashlessCapPercent,
      planDetails?.enrollMinMax.min,
      planDetails?.enrollMinMax?.max,
      planDetails?.cashless_percent_contribution_to_boost_to,
      planDetails?.discount,
      planDetails?.min_weekly_working_hours_for_eligibility,
      planDetails?.monthsInPurchasePeriod,
      planDetails?.monthsInOfferingPeriod,
      planDetails?.purchaseStartDate,
      planDetails?.contribution_change_limit_period,
      planDetails?.cashless_amount_fee_percentage,
      cashlessParticipationOnText,
      cashlessParticipationOffText,
      cashlessParticipationOnOrOffText,
      eligiblePayNameText,
      periodsDates.closestEnrollmentDates.end,
      periodsDates.closestEnrollmentDates.start,
      periodsDates.closestExternalEnrollmentDates,
      periodsDates.currentOfferingDates,
      periodsDates.currentPurchaseDates?.purchaseDate,
      periodsDates.futureEnrollmentDates.end,
      periodsDates.futureEnrollmentDates.start,
      periodsDates.futureOfferingDates,
      periodsDates.closestPurchaseDates.start,
      periodsDates.closestPurchaseDates.end,
      periodsDates.closestPurchaseDates.purchaseDate,
      periodsDates.futurePurchaseDates.purchaseDate,
      periodsDates.futurePurchaseDates.end,
      periodsDates.futurePurchaseDates.start,
      periodsDates.currentPurchaseDates?.lastDayToMakeChanges,
      periodsDates.currentPurchaseDates?.lastDayToWithdraw,
      periodsDates.resetChangeWindowDates,
      effectiveDates.future_date_to_be_withdrawn_at,
      userStateInFuturePeriodOpOrPp.contributionPercentage,
      userStateInClosestPeriodOpOrPp.contributionPercentage,
      dsp,
      payFrequencyCalculatorWeeklyText,
      payFrequencyCalculatorBiWeeklyText,
      payFrequencyCalculatorMonthlyText,
      payFrequencyCalculatorSemiMonthlyText,
      payFrequencyCalculatorLunarText,
      payFrequencyText,
      payFrequencyInHours,
      jurisdictionData?.future_enrollment_end_date_time_zone_display,
      jurisdictionData?.time_zone_display,
      tradeRestrictionWindowStartDatetime,
      tradeRestrictionWindowEndDatetime,
      globalTextVariables?.perPurchasePeriod,
      globalTextVariables?.perOfferingPeriod,
      globalTextVariables?.learnMoreLinkText,
      changesDuringOffering.remainingChangesAnyDirection,
      changesDuringOffering.changesFrequencyAnyDirection,
      changesDuringOffering.remainingChangesUp,
      changesDuringOffering.changesFrequencyUp,
      changesDuringOffering.remainingChangesDown,
      changesDuringOffering.changesFrequencyDown,
      firstName,
      formatTime,
      currencyConversionText,
      login_email,
      first_offer_start_date,
      formatCurrencyFromWholeUnits,
      referralRewardInUserCurrencyWholeUnits,
      returnOnInvestmentPercent,
      employeeCompanyId,
      theme.colors.primary,
      theme.colors.secondary,
    ],
  );

  const unifiedDynamicVariableMapping = useMemo(
    () => ({ ...dynamicVariableKeyToValueMapping, ...(customMapping ?? {}) }),
    [customMapping, dynamicVariableKeyToValueMapping],
  );

  const conditionalContentValues: {
    trueConditions: ReadonlyArray<string>;
    falseConditions: ReadonlyArray<string>;
  } = useMemo(() => {
    const args: ConditionalCmsConditionArgs = {
      isCashlessOnlyEnrollment:
        enrollmentPlan.espp === EnrollmentPlanEspp.EXTERNAL_ESPP,
      isCashlessMatchPlan,
      isEsppOnlyPlan,
      cashlessRevenueModel: planDetails?.cashless_revenue_model ?? null,
      planHasCashlessCapPercent: planDetails?.cashlessCapPercent
        ? planDetails?.cashlessCapPercent < 101
        : false,
      isPlanInSuspensionState,
      sharePrecision: planDetails?.sharePrecision ?? null,
      showWealthManagementFirmName:
        stockPlanAdmin.show_wealth_management_firm_name,
      isWithLastDayToChange:
        !!periodsDates.closestPurchaseDates.lastDayToMakeChanges,
      isUserCountryUsa,
      futureChangePeriodType,
      isEntireIssuerEsppOnly,
      isSellToCoverEnabled: sellToCoverEnabled,
    };
    const customConditions: Array<
    [string, (conditions: ConditionalCmsConditionArgs) => boolean]
    > = customDynamicConditionals
      ? Object.entries(customDynamicConditionals).map(([key, value]) => [
        key,
        () => value,
      ])
      : [];
    return Object.entries(CONDITIONAL_CMS_STRING_CONDITIONS)
      .concat(customConditions)
      .reduce<{
      trueConditions: string[];
      falseConditions: string[];
    }>(
      (
        valuesSoFar,
        [conditionalContentKey, functionToEvaluateConditional],
      ) => {
        const conditionIsTrue = functionToEvaluateConditional(args);
        const oppositeConditionalContentKey =
            CONDITIONAL_CMS_LOGICAL_NOT_PREFIX + conditionalContentKey;
        if (conditionIsTrue) {
          valuesSoFar.trueConditions.push(conditionalContentKey);
          valuesSoFar.falseConditions.push(oppositeConditionalContentKey);
        } else {
          valuesSoFar.falseConditions.push(conditionalContentKey);
          valuesSoFar.trueConditions.push(oppositeConditionalContentKey);
        }
        return valuesSoFar;
      },
      { trueConditions: [], falseConditions: [] },
    );
  }, [
    enrollmentPlan.espp,
    isCashlessMatchPlan,
    isEsppOnlyPlan,
    planDetails?.cashless_revenue_model,
    planDetails?.cashlessCapPercent,
    planDetails?.sharePrecision,
    isPlanInSuspensionState,
    stockPlanAdmin.show_wealth_management_firm_name,
    periodsDates.closestPurchaseDates.lastDayToMakeChanges,
    isUserCountryUsa,
    futureChangePeriodType,
    isEntireIssuerEsppOnly,
    sellToCoverEnabled,
    customDynamicConditionals,
  ]);

  const replaceTextWithCmsDebugInfo = useBooleanUrlSearchParam(
    UrlSearchParamKey.DEBUG_SHOW_CMS_CONTENT_FIELD_NAMES,
  );

  return useMemo(
    () => ({
      insertDynamicVariables: <
        InputType extends JSONSerializableObject | JSONSerializableValue[],
      >(
        objectToReplace: InputType,
      ) =>
        recursivelyReplaceStringWithMapping({
          objectToReplace,
          replacementStringMapping: unifiedDynamicVariableMapping,
          conditionalValues: conditionalContentValues,
          ignoredReplacementStringMapping: ignoredCustomMapping,
          ignoredConditionalValues: ignoredCustomDynamicConditionals,
          metadataForLogging: {
            cmsContentTypeTitle: contentTypeName,
          },
          replaceTextWithCmsDebugInfo,
        }),
      conditionalContentValues,
    }),
    [
      conditionalContentValues,
      unifiedDynamicVariableMapping,
      ignoredCustomMapping,
      ignoredCustomDynamicConditionals,
      contentTypeName,
      replaceTextWithCmsDebugInfo,
    ],
  );
};
