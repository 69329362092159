import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { IOfferingPeriodInfo } from '../../../selectors/interfaces';
import type { StartEndDates } from '../use-get-all-periods';
import { useGetAllOfferingPeriods } from '../use-get-all-periods';
import { userSelector } from '../../../selectors';
import { useEnrollmentPeriodDates } from '../use-enrollment-period';

export interface IOfferingPeriodDates {
  currentOfferingDates: StartEndDates | null;
  futureOfferingDates: StartEndDates;
  closestOfferingDates: StartEndDates;
  resetChangeWindowDates: StartEndDates | null;
}

const getDates = (periodInfo: IOfferingPeriodInfo): StartEndDates => ({
  start: DateTime.fromISO(periodInfo.offeringPeriodStartDate ?? '', {
    setZone: true,
  }),
  end: DateTime.fromISO(periodInfo.offeringPeriodEndDate ?? '', {
    setZone: true,
  }),
});

const getDatesOrNull = (
  periodInfo: IOfferingPeriodInfo | null | undefined,
): StartEndDates | null =>
  (periodInfo?.offeringPeriodStartDate && periodInfo?.offeringPeriodEndDate
    ? getDates(periodInfo)
    : null);

export const useOfferingPeriodDates = (): IOfferingPeriodDates => {
  const { currentPeriod, futurePeriod, closestPeriod } =
    useGetAllOfferingPeriods();
  const { closestEnrollmentDates } = useEnrollmentPeriodDates();
  const { changesDuringOffering } = useSelector(userSelector);

  return useMemo(
    () => ({
      currentOfferingDates: getDatesOrNull(currentPeriod),
      futureOfferingDates: getDates(futurePeriod),
      closestOfferingDates: getDates(closestPeriod),
      resetChangeWindowDates: changesDuringOffering?.isInResetChangeWindow
        ? closestEnrollmentDates
        : null,
    }),
    [
      currentPeriod,
      futurePeriod,
      closestPeriod,
      changesDuringOffering?.isInResetChangeWindow,
      closestEnrollmentDates,
    ],
  );
};
