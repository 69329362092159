/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
import type {
  IIssuerSelector,
  IUserSelector,
  IAuthSelector,
} from './interfaces';

export const authSelector = (state: { authState: IAuthSelector }) =>
  state.authState;
export const issuerSelector = (state: { issuerState: IIssuerSelector }) =>
  state.issuerState;
export const pdfSelector = (state: { userState: { termsConditions: any } }) =>
  state.userState.termsConditions;
export const companySelector = (state: { userState: { company: any } }) =>
  state.userState.company;
export const effectiveDatesSelector = (state: {
  userState: { effectiveDates: any };
}) => state.userState.effectiveDates;
export const nameSelector = (state: { userState: { name: any } }) =>
  state.userState.name;
export const purchaseDateSelector = (state: {
  userState: { purchaseDate: any };
}) => state.userState.purchaseDate;
export const ieBannerSelector = (state: {
  issuerState: { ieBanner: boolean };
}) => state.issuerState.ieBanner;
export const userSelector = (state: { userState: IUserSelector }) =>
  state.userState;

export const enhancedOwnershipSelector = (state: {
  userState: { enhancedOwnership: any };
}) => state.userState.enhancedOwnership;
export const accessCashlessSelector = (state: {
  userState: { accessCashlessParticipation: any };
}) => state.userState.accessCashlessParticipation;
export const eligibleCompensationSelector = (state: {
  userState: { eligibleCompensation: any };
}) => state.userState.eligibleCompensation;
export const cashlessEnhancementSelector = (state: {
  userState: { cashlessEnhancement: any };
}) => state.userState.cashlessEnhancement;
export const payrollContributionSelector = (state: {
  userState: { payrollContribution: any };
}) => state.userState.payrollContribution;
export const totalBuyingPowerSelector = (state: {
  userState: { totalBuyingPower: any };
}) => state.userState.totalBuyingPower;
export const enrollCashlessSelector = (state: {
  userState: { enrollCashlessParticipation: any };
}) => state.userState.enrollCashlessParticipation;
export const contributionSelector = (state: {
  userState: { contributionPercentage: any };
}) => state.userState.contributionPercentage;
export const minMaxContributionSelector = (state: {
  userState: { enrollMinMax: any };
}) => state.userState.enrollMinMax;
