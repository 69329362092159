/* eslint-disable @typescript-eslint/no-explicit-any */
export interface LatestStockPrice {
  price: string;
  symbol: string;
}

/** These need to be the same strings as in the backend/database;
 * don't change them without talking to backend engineers:
 */
export enum PayFrequency {
  WEEKLY = 'weekly',
  BIWEEKLY = 'biweekly',
  MONTHLY = 'monthly',
  SEMIMONTHLY = 'semimonthly',
  LUNAR = 'lunar',
}

export interface EnrollMinMax {
  max: number;
  min: number;
}

export type DocumentData = Readonly<{
  doc_type: string;
  link: string;
}>;

export type DocumentsData = Readonly<{
  cashless_enrollment_documents?: ReadonlyArray<DocumentData>;
  espp_enrollment_documents?: ReadonlyArray<DocumentData>;
  informational_documents?: ReadonlyArray<DocumentData>;
}>;

type JurisdictionFeatures = {
  email_validation: boolean;
  wealth_management_interest_question: boolean;
  referral_program_enabled: boolean;
  user_is_required_to_scroll_through_contracts: boolean;
};

export type JurisdictionData = Readonly<{
  currency_code: string;
  currency_symbol: string;
  currency_display_symbol_left: string;
  currency_display_symbol_space_left: boolean;
  currency_display_symbol_right: string;
  currency_display_symbol_space_right: boolean;
  currency_display_include_decimal: boolean;
  exchange_rate: string;
  exchange_rate_symbol: string;
  documents_by_language: Readonly<{
    [languageCode: string]: DocumentsData;
  }>;
  plan_details: IPlanDetails;
  sell_to_cover_enabled: boolean;
  default_language: string;
  supported_languages: ReadonlyArray<string>;
  time_zone_display: string;
  future_enrollment_end_date_time_zone_display: string;
  jurisdiction_features: JurisdictionFeatures;
}>;

export type NameByLanguages = Readonly<{
  [languageCode: string]: string;
}>;

export enum ChangeLimitPeriod {
  OFFERING_PERIOD = 'OFFERING_PERIOD',
  PURCHASE_PERIOD = 'PURCHASE_PERIOD',
}

// Do not change the order as the login page uses the current order
export enum LoginMethod {
  SSO = 'sso',
  PASSWORDLESS_EMAIL = 'passwordless_email',
  BUSINESS_GROUP = 'business_group',
}

export enum EmployeeLoginType {
  REGULAR = 'regular',
  ADMIN = 'admin',
  TERMINATED = 'terminated',
}

export interface LoginMethodConfig {
  user_case: EmployeeLoginType[];
  oauth_login_url?: string;
  business_groups?: string[];
}

export interface ISsoProviderInfo {
  sso_provider_color: string | null;
  sso_provider_logo: string | null;
  sso_provider_name: string | null;
}

export interface ILoginInformation extends ISsoProviderInfo {
  login_method_config: Readonly<{
    [loginMethod in LoginMethod]?: LoginMethodConfig;
  }>;
  show_login_page: boolean;
  supported_login_methods: LoginMethod[] | null;
}

/** These are the string values that the backend sends */
export enum EffectiveGrantDate {
  OFFERING_PERIOD_START = 'offer_start_date',
  PURCHASE_DATE = 'purchase_date',
}

export interface IPlanDetails {
  // Cashless stuff is null for espp-only plans
  cashlessCapDollar: number | null;
  cashlessCapPercent: number | null;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  cashlessMethodology: 'MATCH' | string | null;
  discount: number;
  employerMaxSellPricePercent: number | null;
  enrollMinMax: EnrollMinMax;
  /** This should be calculated like the US federal limit
   * if not null. It *may apply to non-US countries*
   * (some issuers want all their plans to be capped the
   * same way as US plans for consistency, even if
   * not legally required):
   *
   *(federalLimit is currently the limit on the amount of money for any country) */
  federalLimit: number | null;
  purchaseEndDate: string;
  purchaseStartDate: string;
  sharePrecision: number;
  monthsInOfferingPeriod: number;
  monthsInPurchasePeriod: number;
  // Null if not fee-based:
  cashless_amount_fee_percentage: number | null;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  cashless_revenue_model: 'FEE' | 'DSP' | string | null;
  cashless_percent_contribution_to_boost_to: number | null;
  min_weekly_working_hours_for_eligibility: number | null;
  contribution_change_limit_period?: ChangeLimitPeriod;
  offering_period_employee_share_limit: number | null;
  purchase_period_employee_share_limit: number | null;
  annual_employee_share_limit: number | null;
  purchase_period_employee_cashless_dollar_limit: number | null;
  offering_period_employee_cashless_dollar_limit: number | null;
  withdrawn_from_future_becomes_eligible_for_next_offering: boolean | null;
  cashless_allowed_at_cashless_cap: boolean;
  /** The grant date of the option for the purposes of 26 U.S. Code § 423.
   * This is used, for example, when calculating the max number of allowed
   * shares under the IRS $25k limit.
   */
  effective_grant_date: EffectiveGrantDate;
  /** Whether the plan offers a lookback discount (i.e., if this is true,
   * and the stock price on the OP start date is lower than the stock price
   * on the purchase date, then the OP start date price is used, which gives
   * participants an extra discount) */
  lookback_offered: boolean;
  /** If a person contributes above this number, show a warning saying that their contribution
   * is really high:
   */
  high_election_warning_triggered_above_percentage: number | null;
  enrollment_tied_to_purchase_period: boolean;
}

export interface IStockPlanAdmin {
  login_url: string;
  privacy_policy_url: string;
  terms_and_conditions_url: string;
  tax_information_url: string;
  wealth_management_firm_name: string;
  show_wealth_management_firm_name: boolean;
}

export interface IIssuerSelector {
  issuerNotFoundError: boolean;
  cashlessOnlyEnrollment: boolean;
  cashlessOnlyEnrollmentIsConcurrent: boolean;
  companyColor: string;
  companyRegisteredName: string;
  inactivityModal: string;
  sessionWarningModal: boolean;
  sessionEndModal: boolean;
  getAssetsLoading: boolean;
  getAssetsHadError: boolean;
  initialPageLoadHandlerCalled: boolean;
  favicon: string;
  serverError: boolean;
  latestStockPrice: LatestStockPrice;
  /** @deprecated */
  productName: string;
  language: string;
  productNameES: string;
  planName: string;
  planNameFRCA: string;
  planNameES: string;
  productNameFRCA: string;
  backupProductName: string;
  enrollmentOnly: boolean;
  companyTicker: string;
  listing_stock_exchange: string;
  companyShortName: string | undefined;
  employerOnboard: boolean;
  ipAddressInGdprCountry: boolean;
  displayName: string;
  /** @deprecated */
  employerPlanName: string;
  adminName: string;
  contactEmail: string;
  gtmId: string;
  sso: string;
  login: ILoginInformation;
  jurisdictionByCountryCode: Readonly<{
    [countryCode: string]: JurisdictionData;
  }>;
  languageCodeToLanguageInfo: Readonly<{
    [languageCode: string]: {
      cms_id: number;
      display_name: string;
    };
  }>;
  planNameByLang: NameByLanguages;
  productNameLongByLang: NameByLanguages;
  productNameShortByLang: NameByLanguages;
  generalProductNameLongByLang: NameByLanguages;
  generalProductNameShortByLang: NameByLanguages;
  stockPlanAdmin: IStockPlanAdmin;
  // The backend detected the user's IP address as belonging to this country:
  detectedCountryCode: string;
  detectedStateCode: string;
  first_offer_start_date: string;
  /** The URL where users should go for external enrollment, if different from
   * their stock plan admin.
   */
  externalEnrollmentSiteUrl?: string | null;
  /** The name of the provider of external enrollment, if different from
   * the stock plan admin.
   */
  externalEnrollmentSiteProvider?: string | null;
  isPlanInSuspensionState: boolean | null;
  periodInfo: IPeriodInfo;
  isEntireIssuerEsppOnly: boolean;
  isAutoEnrollmentEnabled: boolean;
  isScheduledEnrollmentAllowed: boolean;
  cms_site_id?: string | null;
}

export interface Calculator {
  cashlessCapDollar: number;
  federalLimit: number;
  cashlessCapPercent: number;
  discount: number;
  frequency: string;
  lookback: boolean;
  perOfferingShareLimit: number;
  planLimitPercent: number;
  cashlessEnhancement: number;
  employerMaxSellPricePercent: number;
  salaried: boolean;
  paycheckAmountInCurrencyHundredths: number;
  scheduledWeeklyHours: number;
}

export interface Company {
  ticker: string;
  shortname: string;
  adminEmail: string;
  productName: string;
  contactEmail: string;
}
export interface ChangesDuringOfferingFromApi {
  can_make_rollover_change: boolean;
  cashlessChangeAllowed: boolean;
  changeDirection: string;
  changesDuringOffering: boolean;
  changesFrequency: number | null;
  changesFrequencyDown: number | null;
  changesFrequencyUp: number | null;
  limitDirectionSpecific: boolean;
  remainingChanges: number | null;
  remainingChangesDown: number | null;
  remainingChangesUp: number | null;
  resumptionAllowed: boolean;
  resumptionCountsAsIncrease: boolean;
  suspensionAllowed: boolean;
  suspensionCountsAsDecrease: boolean;
  is_in_reset_change_window: boolean;
}

export interface ChangesDuringOffering {
  /** The flag means that the user can make changes to the current OP and
   *  enrollment is open for the future OP (or a PP enrollment) */
  canMakeRolloverChange: boolean;
  cashlessChangeAllowed: boolean;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  changeDirection: 'up' | 'down' | 'both' | string;
  /** @deprecated changesDurringOffering is basically the legacy version of
   * UpdateElectionAction being within current_op_allowed_actions */
  changesDuringOffering: boolean;
  changesFrequencyAnyDirection: number | null;
  changesFrequencyDown: number | null;
  changesFrequencyUp: number | null;
  remainingChangesAnyDirection: number | null;
  remainingChangesDown: number | null;
  remainingChangesUp: number | null;
  resumptionAllowed: boolean;
  resumptionCountsAsIncrease: boolean;
  suspensionAllowed: boolean;
  suspensionCountsAsDecrease: boolean;
  isInResetChangeWindow: boolean;
}

export interface Compensation {
  salaried: boolean;
  rate: number;
  scheduledWeeklyHours: number;
  cashlessEnhancementPercentageLimit: number;
  frequency: string;
}

export interface Answer {
  response: boolean | string;
  additionInfo?: any;
}

export interface Question {
  question: string;
  answer: Answer;
}

export interface ExpirationDate {
  month?: any;
  day?: any;
  year?: any;
}

export interface AdditionInfo {
  country?: any;
  birthCountry?: any;
  visa?: any;
  expirationDate: ExpirationDate;
}

export interface Answer4 extends Answer {
  permanentResident?: any;
  additionInfo: AdditionInfo;
}

export interface Question4 extends Question {
  question: string;
  answer: Answer4;
}

export type EtradeQuestions = {
  [key: string]: Question | Question4;
} & {
  question1: Question;
  question2: Question;
  question3: Question;
  question4: Question4;
  question5: Question;
};

export interface EffectiveDates {
  enrollmentEndDate?: any;
  enrollmentOpen: boolean;
  enrollmentStartDate?: any;
  futureEnrollmentStartDate?: any;
  futureEnrollmentEndDate?: any;
  futurePurchaseDate?: any;
  lastDayToChange?: any;
  offerEndDate?: any;
  offerStartDate?: any;
  offerOpen: boolean;
  pastOfferEndDate?: any;
  pastPurchaseDate?: any;
  futureOfferStartDate?: any;
  futureOfferEndDate?: any;
  futureFutureOfferStartDate?: any;
  withdraw?: boolean;
  withdrawFromFuture?: boolean;
  /** We only would want to use this when there is a current OP for the user.
   * So it's possible the value is null because BE only determines it
   * when they are enrolled in a current OP, and are withdrawn from the future on that OP */
  future_date_to_be_withdrawn_at?: string;
}

export interface Name {
  alternate_email?: string;
  alternate_email_preferred: boolean;
  alternate_email_verification_timestamp?: string;
  /** if it's false, that means the user is using sso instead of passwordless */
  alternateEmailCanBeUsedForLogin: boolean;
  company_provided_email: string;
  email: string;
  first: string;
  last: string;
  login_email?: string;
  pending_alternate_email?: string;
}

export interface TermsConditions {
  esppAgreement: string;
  enrollmentAgreement: string;
  cashlessAgreement: string;
  puertoRicoAgreement: string;
}

export interface AgreementStatus {
  enrollmentAgreement: boolean;
  empploymentAgreement: boolean;
  cashlessAgreement: boolean;
}

export interface Home {
  homeAddress1: string;
  homeAddress2?: any;
  homeCity: string;
  homeCountry: string;
  homeState: string;
  homeZip: string;
  homePhone: string;
}

export interface User {
  home: Partial<Home>;
}

export interface EmployeeResidence {
  livesInPR: boolean;
  worksInPR: boolean;
}
export interface ICashlessReserve {
  canAddCashlessReserve: boolean;
  cashlessReserveCeiling: number | null;
  cashlessReserveChangeDirection: string;
  usedCashlessReserve: boolean;
  cashlessCeilingNotEqualToCashlessMax: boolean;
}

export interface IEnrollmentPeriodInfoFromApi {
  start_date: string;
  end_date: string;
  traditional_enrollment: boolean;
  cashless_enrollment: boolean;
  first_enrollment_for_offering: boolean;
  purchase_period_enrollment: boolean;
  traditional_enrollment_is_external: boolean;
  cashless_enrollment_is_external: boolean;
  reset_change_window: boolean;
  next_enrollment_period_is_connected: boolean;
  offering_period_id: number | null;
}

export interface IEnrollmentPeriodInfo {
  startDate: string;
  endDate: string;
  traditionalEnrollment: boolean;
  traditionalEnrollmentIsExternal: boolean;
  cashlessEnrollment: boolean;
  firstEnrollmentForOffering: boolean;
  purchasePeriodEnrollment: boolean;
  resetChangeWindow: boolean;
  nextEnrollmentPeriodIsConnected: boolean;
  offeringPeriodId: number | null;
}

export interface IOfferingPeriodInfo {
  offeringPeriodEndDate: string | null;
  offeringPeriodStartDate: string | null;
  enrollmentStartDate: string | null;
  enrollmentEndDate: string | null;
  lastDayToMakeChanges: string | null;
  offeringPeriodId: number | null;
}

export interface IPurchasePeriodInfo {
  purchaseDate: string | null;
  purchasePeriodEndDate: string | null;
  purchasePeriodStartDate: string | null;
  lastDayToMakeChanges: string | null;
  lastDayToWithdraw: string | null;
  offeringPeriodId: number | null;
}

export const convertEnrollmentPeriodInfo = (
  period: IEnrollmentPeriodInfoFromApi,
): IEnrollmentPeriodInfo => ({
  startDate: period.start_date,
  endDate: period.end_date,
  traditionalEnrollment: period.traditional_enrollment,
  traditionalEnrollmentIsExternal: period.traditional_enrollment_is_external,
  cashlessEnrollment: period.cashless_enrollment,
  firstEnrollmentForOffering: period.first_enrollment_for_offering,
  purchasePeriodEnrollment: period.purchase_period_enrollment,
  resetChangeWindow: period.reset_change_window,
  nextEnrollmentPeriodIsConnected: period.next_enrollment_period_is_connected,
  offeringPeriodId: period.offering_period_id,
});

export interface IGenericPeriodInfo<
  TEnrollmentPeriodInfo,
  TOfferingPeriodInfo,
  TPurchasePeriodInfo,
> {
  currentEnrollmentPeriod: TEnrollmentPeriodInfo | null;
  currentOfferingPeriod: TOfferingPeriodInfo | null;
  currentPurchasePeriod: TPurchasePeriodInfo | null;
  futureEnrollmentPeriod: TEnrollmentPeriodInfo;
  futureOfferingPeriod: TOfferingPeriodInfo;
  futurePurchasePeriod: TPurchasePeriodInfo;
}

export type IPeriodInfo = IGenericPeriodInfo<
IEnrollmentPeriodInfo,
IOfferingPeriodInfo,
IPurchasePeriodInfo
>;

// TODO: Pretty similar to AgreementStatus
export interface IPdfAgreementStatus {
  enrollmentAgreed: boolean;
  employeeAgreed: boolean;
  cashlessAgreed: boolean;
  // TODO: Never used, the only occurance is commented
  puertoRicoAgreed: boolean;
}

/** These strings match the API responses, don't change them! */
export enum PotentialOfferingPeriodAction {
  WithdrawElectionAction = 'WithdrawElectionAction',
  UpdateElectionAction = 'UpdateElectionAction',
  /** Enroll is only sent if both Enroll and Update are allowed */
  EnrollElectionAction = 'EnrollElectionAction',
  SuspendElectionAction = 'SuspendElectionAction',
  ResumeElectionAction = 'ResumeElectionAction',
  UndoFutureWithdraw = 'UndoFutureWithdraw',
  ScheduledEnrollmentAction = 'ScheduledEnrollmentAction',
  CancelScheduledEnrollmentAction = 'CancelScheduledEnrollmentAction',
}

export interface IUserSelector {
  userDataHasLoaded: boolean;
  accessCashlessParticipation: boolean;
  accessESPP: boolean;
  adminAccessOnly: boolean;
  accordionStateOpen: boolean;
  cashlessEnhancement: number;
  cashlessEnhancementPercent: number;
  calculator: Calculator;
  company: Company;
  changesDuringOffering: ChangesDuringOffering;
  inTradeRestrictionWindow: boolean;
  compensation: Compensation;
  /** used in ABC */
  contributionPercentageCurrent?: number;
  contributionPercentageFuture: number | null | undefined;
  pastContributionPercentage?: number;
  declineCashlessParticipation: boolean;
  etradeQuestions: EtradeQuestions;
  effectiveDates: EffectiveDates;
  periodInfo?: IPeriodInfo;
  eligibleCompensation: number;
  employeeCompanyId?: string | null;
  enhancedOwnership: number;
  enrollMinMax: EnrollMinMax;
  enrollCashlessParticipationFromDB: boolean;
  enrollCashlessParticipationFuture: boolean | null;
  enrollCashlessParticipation: boolean;
  enrolledFromReset: boolean;
  pastEnrollCashlessParticipation: boolean;
  // If the user was enrolled in the *previous period*:
  pastEnrolled: boolean;
  employeeWorkCountry: string;
  employeeWorkState: string;
  everBeenInPurchase: boolean;
  everHadCashlessGoingIntoPurchase: boolean;
  hasTaxDocuments: boolean;
  hasProvidedUsSsn: boolean;
  hasProvidedUsTin: boolean;
  referralProgramEnabled: boolean;
  referralProgramCurrentlyActive: boolean;
  referralRewardInUserCurrencyWholeUnits: number;
  name: Name;
  special_user: boolean;
  displayResetPopup: boolean;
  payrollContribution: number;
  purchaseDate?: any;
  termsConditions?: TermsConditions;
  agreementStatus: AgreementStatus;
  user: User;
  totalBuyingPower: number;
  verifyEmail: boolean;
  emailDateVerified?: string;
  employeeResidence: EmployeeResidence;
  id: number;
  apexStatus: boolean;
  futureChangePeriodType: 'OFFERING' | 'PURCHASE' | null;
  isEligibleForNextOfferingAfterWithdrawingFromFuture: boolean;
  enrolled: boolean;
  enrolledInFuture: boolean;
  // enrollmentRequiresVerifiedEmail is for the last step of enrollment,
  // we usually ask for an email if verifyEmail is False
  enrollmentRequiresVerifiedEmail: boolean;
  /** the suspended flag of the current OP */
  suspended: boolean;
  terminated: boolean;
  withdrawn: boolean;
  withdrawnFromFuture: boolean;
  reenrollmentRequired: boolean;
  magicLinkRestricted: boolean;
  // magic_login_enrollment_requires_verification is when they’re logged in via a magic link,
  // if we require them to do further validation before enrolling
  magicLoginEnrollmentRequiresVerification: boolean;
  verificationMethod: string;
  externalEnrollmentPending: boolean;
  executive: boolean;
  cashlessReserve: ICashlessReserve;
  e_sign: string;
  completedFlag?: boolean;
  userIsAnOrganization?: boolean;
  fullyExercisedPositions: boolean | null | undefined;
  defaultTalkToWealthManagerAnswer: boolean;
  selectedTalkToWealthManager?: boolean | null;
  sellToCoverEnabled: boolean;
  sellToCoverTaxPercent: number | null;
  canSelectAutoSalePercent: boolean;
  autoSaleElectionPercent: number | null;
  shouldAskAboutWealthManager?: boolean;
  personAssociatedWithGdprCountry: boolean;
  pdfAgreementStatus: IPdfAgreementStatus;
  isAutoEnrolledIntoOp: boolean;
  isIssuerAdmin: boolean;
  isIssuerAdminAuthenticated: boolean;
  currentOpAllowedActions?: ReadonlyArray<PotentialOfferingPeriodAction>;
  /** The potentially allowed means does the plan support changes. */
  currentOpPotentiallyAllowedActions?: ReadonlyArray<PotentialOfferingPeriodAction>;
  futureOpAllowedActions?: ReadonlyArray<PotentialOfferingPeriodAction>;
  /** The potentially allowed means does the plan support changes. */
  futureOpPotentiallyAllowedActions?: ReadonlyArray<PotentialOfferingPeriodAction>;
  futureOpScheduledEnrollment: null | {
    accepted_cashless: boolean;
    contribution_percentage: number;
    offering_period_id: number;
  };
  tradeRestrictionWindowStartDatetime: string | null;
  tradeRestrictionWindowEndDatetime: string | null;
  /** This is only populated for admin users */
  refinerSurveyResultsIframeUrl?: string | null;
  documentsByLanguage: null | Readonly<{
    [languageCode: string]: DocumentsData;
  }>;
}

export enum UsResidentStatus {
  CITIZEN = 'yes',
  PERMANENT_RESIDENT = 'permanent-resident',
  NONE = 'no',
}

/**
 * This is saved to local storage. IF YOU WANT TO MAKE BREAKING CHANGES to this interface,
 * you should change the key this is saved under in localstorage.ts, so that you don't
 * get backwards compatibility bugs when trying to read old values that were saved to local
 * storage on a previous version. OTHERWISE YOU WILL GET BUGS.
 */
export type IEnrollmentFormData = {
  demographicQuestions: {
    /**
     * Are you a control person of a publicly traded company?
     * (Director, Officer, or minimum 10% Stock Holder)
     */
    controlPubliclyTradedCompany: {
      response: boolean;
      /** Please list the ticker for any such company: */
      additionalInfo?: string;
    };
    /**
     * Are you affiliated with, work with or work for a member
     * firm of a Stock Exchange or FINRA?
     */
    affiliatedWithStockExchangeOrFinra: {
      response: boolean;
      /** Please list the name of the firm(s): */
      additionalInfo?: string;
    };
    /**
     * Are you a current or former Public Official or someone
     * with a high-profile political role entrusted with a prominent public function?
     */
    publicOfficial: {
      response: boolean;
      /** Please provide the names of all immediate family members, including former spouses: */
      additionalInfo?: string;
    };
    /** Are you a United States Citizen? */
    usCitizen: {
      response: UsResidentStatus;
      additionalInfo?: {
        country: string;
        type: string;
        day: string;
        month: string;
        year: string;
      };
    };
    /** 'Is your total net worth (excluding your residence) greater than $5,000?' */
    netWorthOver5K: {
      response: boolean;
    };
  };
};

export interface IEmail {
  email?: string;
  emailExists?: boolean;
  error?: string | null;
  verified?: boolean;
}

export interface IUser {
  name?: { first?: string; last?: string };
  officialLanguage?: string;
}

export interface IAuthSelector {
  currentUser: IUser;
  isAuthenticated: boolean;
  error?: string | null;
  loading: boolean;
  isLocked: boolean;
  attempt: number;
  resetForm: boolean;
  verifyingCookie: boolean;
  viewPortal: boolean;
  viewEnterSSN: boolean;
  empID: string;
  emailVerificationLogin?: IEmail;
}
