import { useContext, useCallback } from 'react';
import { PageTitleListContext } from '../page-title-list-context-provider';
import type { Page } from '../../../../app/page-url-path';
import { PageTitleKeyForPage } from '../../../../app/page-title-key';
import type { IPageTitleData } from '../../../../dotcms/dotcms-models/dotcms-page-title-list';

export const useGetTitleForPage = () => {
  const { data: pageTitles, loading, error } = useContext(PageTitleListContext);

  const getCmsTitleByKey = useCallback(
    (key: keyof IPageTitleData): string | undefined => {
      if (pageTitles) {
        const documentTitle = pageTitles[key];

        if (documentTitle && documentTitle.charAt(0) !== ' ') {
          return documentTitle;
        }
      }

      return undefined;
    },
    [pageTitles],
  );

  const getCmsTitleForPage = useCallback(
    (page: Page): string | undefined => {
      const pageTitleKeyInCms = PageTitleKeyForPage[page];
      return getCmsTitleByKey(pageTitleKeyInCms);
    },
    [getCmsTitleByKey],
  );

  return {
    getCmsTitleForPage,
    getCmsTitleByKey,
    titlesFromCmsLoaded: !loading && (!!pageTitles || !!error),
  };
};
