/** @jsxImportSource @emotion/react */
// see - https://www.figma.com/file/pv2HV6DTSKY612VWeYWYdL/CE22-Design-System?node-id=3%3A10
import type { PropsWithChildren } from 'react';
import React from 'react';
import type { Interpolation, Theme } from '@emotion/react';
import type { Theme as MuiTheme } from '@mui/material';
import { createTheme } from '@mui/material';
import type { ITheme, ThemeColor } from '../../styles/theme';
import { theme as defaultTheme } from '../../styles/theme';
import type { SettingBasedOnBreakpoints } from '../../../hooks/use-setting-based-on-breakpoints';
import { useSettingBasedOnBreakpoints } from '../../../hooks/use-setting-based-on-breakpoints';
import type { IPropsWithTestId } from '../../../constants/data-element-tracking-ids';
import { ELEMENT_ID_DATA_ATTRIBUTE } from '../../../constants/data-element-tracking-ids';

export enum TypographyVariant {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  LARGE_BODY_BOLD = 'largeBodyBold',
  LARGE_BODY_REGULAR = 'largeBodyRegular',
  MEDIUM_BODY_BOLD = 'mediumBodyBold',
  MEDIUM_BODY_REGULAR = 'mediumBodyRegular',
  MEDIUM_BODY_ITALIC = 'mediumBodyItalic',
  LINKS_SEMIBOLD = 'linksSemibold',
  LINKS_MEDIUM = 'linksMedium',
  LINKS_SMALL = 'linksSmall',
  SMALL_BODY_BOLD = 'smallBodyBold',
  SMALL_BODY_REGULAR = 'smallBodyRegular',
  LARGE_EYEBROW = 'largeEyebrow',
  SMALL_EYEBROW = 'smallEyebrow',
  DISCLAIMER = 'disclaimer',
  DATA_TABLE_VALUES = 'dataTableValues',
  CALENDAR_EVENT_CONTENTS = 'calendarEventContents',
  OVERLINE = 'overline',
}

type TypographyStyles = {
  fontFamily?: string;
  fontWeight: string;
  fontSize: string;
  letterSpacing: string;
  lineHeight: string;
  color: ThemeColor;
  fontStyle?: string;
  textTransform?: 'uppercase';
  textDecorationLine?: string;
};

export const getTypographyStyles = (
  currentVariant: TypographyVariant,
  theme: ITheme,
  color?: ThemeColor,
) => {
  const { colors } = theme;
  const linkStyles = {
    textDecorationLine: 'underline',
    color: colors.primary,
    '&: active': {
      color: colors.secondary,
    },
    cursor: 'pointer',
    a: {
      color: color || colors.primary,
      '&: active': {
        color: colors.secondary,
      },
    },
  };
  const styles: Record<TypographyVariant, TypographyStyles> = {
    h1: {
      fontWeight: '700',
      fontSize: '60px',
      letterSpacing: '0%',
      lineHeight: '64px',
      color: colors.secondary,
    },
    h2: {
      fontWeight: '700',
      fontSize: '40px',
      letterSpacing: '0%',
      lineHeight: '44px',
      color: colors.secondary,
    },
    h3: {
      fontWeight: '700',
      fontSize: '30px',
      letterSpacing: '0%',
      lineHeight: '32px',
      color: colors.secondary,
    },
    h4: {
      fontWeight: '400',
      fontSize: '30px',
      letterSpacing: '0%',
      lineHeight: '36px',
      color: colors.secondary,
    },
    largeBodyBold: {
      fontWeight: '700',
      fontSize: '18px',
      letterSpacing: '0%',
      lineHeight: '28px',
      color: colors.secondary,
    },
    largeBodyRegular: {
      fontWeight: '400',
      fontSize: '18px',
      letterSpacing: '0%',
      lineHeight: '28px',
      color: colors.secondary,
    },
    mediumBodyBold: {
      fontWeight: '700',
      fontSize: '16px',
      letterSpacing: '0%',
      lineHeight: '24px',
      color: colors.secondary,
    },
    mediumBodyRegular: {
      fontWeight: '400',
      fontSize: '16px',
      letterSpacing: '0%',
      lineHeight: '24px',
      color: colors.secondary,
    },
    mediumBodyItalic: {
      fontStyle: 'italic',
      fontWeight: '400',
      fontSize: '16px',
      letterSpacing: '0%',
      lineHeight: '24px',
      color: colors.secondary,
    },
    linksSemibold: {
      fontWeight: '600',
      fontSize: '16px',
      letterSpacing: '0%',
      lineHeight: '24px',
      ...linkStyles,
    },
    linksMedium: {
      fontWeight: '500',
      fontSize: '16px',
      letterSpacing: '0%',
      lineHeight: '24px',
      ...linkStyles,
    },
    linksSmall: {
      fontWeight: '500',
      fontSize: '14px',
      letterSpacing: '0%',
      lineHeight: '20px',
      ...linkStyles,
    },
    smallBodyBold: {
      fontWeight: '700',
      fontSize: '14px',
      letterSpacing: '0%',
      lineHeight: '20px',
      color: colors.secondary,
    },
    smallBodyRegular: {
      fontWeight: '400',
      fontSize: '14px',
      letterSpacing: '0%',
      lineHeight: '20px',
      color: colors.secondary,
    },
    largeEyebrow: {
      fontWeight: '700',
      fontSize: '14px',
      letterSpacing: '3px',
      lineHeight: '20px',
      color: colors.primary,
      textTransform: 'uppercase',
    },
    smallEyebrow: {
      fontWeight: '700',
      fontSize: '12px',
      letterSpacing: '3px',
      lineHeight: '12px',
      color: colors.primary,
      textTransform: 'uppercase',
    },
    disclaimer: {
      fontWeight: '400',
      fontSize: '12px',
      letterSpacing: '0%',
      lineHeight: '16px',
      color: colors.secondary,
    },
    dataTableValues: {
      fontFamily: '"Inconsolata", sans-serif',
      fontWeight: '600',
      fontSize: '16px',
      letterSpacing: '0%',
      lineHeight: '24px',
      color: colors.secondary,
    },
    calendarEventContents: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '17.5px',
      letterSpacing: '0%',
      color: colors.secondary,
      textTransform: 'uppercase',
    },
    overline: {
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '1',
      color: colors.secondary,
      textTransform: 'uppercase',
    },
  };

  const typographyClasses = {
    fontFamily: '"Barlow", sans-serif',
    ...styles[currentVariant],
    margin: 0,
    color: color || styles[currentVariant].color,
  };

  return typographyClasses;
};

interface IProps extends Partial<IPropsWithTestId> {
  variant?: TypographyVariant | SettingBasedOnBreakpoints<TypographyVariant>;
  theme?: ITheme;
  muiTheme?: MuiTheme;
  color?: ThemeColor;
  additionalStyles?: Interpolation<Theme>;
}

const Typography: React.FC<PropsWithChildren<IProps>> = ({
  children,
  variant = TypographyVariant.MEDIUM_BODY_REGULAR,
  theme = defaultTheme,
  muiTheme = createTheme(),
  color,
  additionalStyles = {},
  dataElementId,
}) => {
  const { getSettingBasedOnBreakpoint } =
    useSettingBasedOnBreakpoints(muiTheme);

  const currentVariant =
    typeof variant === 'string'
      ? variant
      : getSettingBasedOnBreakpoint(variant);
  const styles = getTypographyStyles(currentVariant, theme, color);

  return (
    <div
      css={[styles, additionalStyles]}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...{ [ELEMENT_ID_DATA_ATTRIBUTE]: dataElementId }}
    >
      {children}
    </div>
  );
};

export default Typography;
