import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import LogrocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer';

// LogRocket doesn't directly export their `IRequest` or `IResponse` type, but we need it
// to define our own function that matches their interface, so we have to
// extract it in a kind of roundabout way here:
type LogRocketIOptions = NonNullable<Parameters<typeof LogRocket.init>[1]>;
type LogRocketIOptionsNetwork = NonNullable<LogRocketIOptions['network']>;
type LogRocketIOptionsRequestSanitizer = NonNullable<
LogRocketIOptionsNetwork['requestSanitizer']
>;
type LogRocketIRequest = Parameters<LogRocketIOptionsRequestSanitizer>[0];
type LogRocketIOptionsResponseSanitizer = NonNullable<
LogRocketIOptionsNetwork['responseSanitizer']
>;
type LogRocketIResponse = Parameters<LogRocketIOptionsResponseSanitizer>[0];

// LogrocketFuzzySanitizer doesn't directly export its `INetworkRequestResponse` type,
// but we need that also:
type LogrocketFuzzySanitizerINetworkRequestResponse = Parameters<
ReturnType<(typeof LogrocketFuzzySanitizer)['setup']>['requestSanitizer']
>[0];

const privateFieldNames = [
  'last4',
  'day',
  'month',
  'year',
  'ssn',
  'homeAddress1',
  'homeAddress2',
  'homeCity',
  'homeState',
  'homeZip',
  'homeCountry',
  'homePhone',
  'first',
  'last',
  'email',
  'espp',
  'cp',
  'pr',
  'wd',
  'tax',
];

const HEADERS_TO_WIPE = ['x-secret'];

function wipeHeader<
  T extends { headers: { [key: string]: string | undefined } },
>(request: T): T {
  if (request.headers) {
    HEADERS_TO_WIPE.forEach((header) => {
      if (request.headers[header]) {
        request.headers[header] = undefined;
      }
    });
  }
  return request;
}

export const initializeLogrocket = () => {
  const { requestSanitizer, responseSanitizer } =
    LogrocketFuzzySanitizer.setup(privateFieldNames);

  LogRocket.init('oqhjjm/aramark-microsite', {
    console: {
      isEnabled: {
        log: false,
        debug: false,
      },
    },
    network: {
      requestSanitizer: (request) =>
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        requestSanitizer(
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          wipeHeader(request) as LogrocketFuzzySanitizerINetworkRequestResponse,
        ) as LogRocketIRequest,
      responseSanitizer: (response) =>
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        responseSanitizer(
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          wipeHeader(
            response,
          ) as LogrocketFuzzySanitizerINetworkRequestResponse,
        ) as LogRocketIResponse,
    },
  });
  setupLogRocketReact(LogRocket);
};
