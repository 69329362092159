import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector, issuerSelector } from '../../selectors';
import { useGetIssuerShortname } from '../../hooks/use-get-issuer-shortname';
import {
  fetchCompanyInfo,
  setInitialPageLoadHandlerCalled,
} from '../../slices/issuer-reducer';
import { verifyCookie } from '../../slices/auth';
import { useAreFeatureFlagsLoading } from '../../featureFlags/useAreFeatureFlagsLoading';
import { useIsHalfMagicLoggedIn } from '../../hooks/useIsHalfMagicLoggedIn';
import { GlobalLoadingContext } from '../abc/global-loading-context/global-loading-context';
import { useGetCurrentPage } from '../../app/get-current-page';
import { Page } from '../../app/page-url-path';

export const useHandleInitialPageLoad = () => {
  const issuerShortName = useGetIssuerShortname();
  const [getAssetsCalledForIssuer, setGetAssetsCalledForIssuer] = useState<
  string | undefined
  >(undefined);
  const dispatch = useDispatch();
  const { setOperationLoading } = useContext(GlobalLoadingContext);

  const currentPage = useGetCurrentPage();

  useEffect(() => {
    if (currentPage === Page.ANTI_BOT_MAGIC_LINK_ENROLLMENT_WELCOME) {
      // This page should not make any backend calls. The page is supposed to protect
      // the backend from getting overwhelmed with bot traffic from email link scanners.
      return;
    }
    if (issuerShortName && getAssetsCalledForIssuer !== issuerShortName) {
      setGetAssetsCalledForIssuer(issuerShortName);
      dispatch(setInitialPageLoadHandlerCalled());
      dispatch(
        fetchCompanyInfo({
          ticker: issuerShortName,
          maybeFunctionToSetGlobalLoadingState: setOperationLoading,
        }),
      );
      dispatch(
        verifyCookie({
          companyShortName: issuerShortName,
          maybeFunctionToSetGlobalLoadingState: setOperationLoading,
        }),
      );
    }
  }, [
    currentPage,
    dispatch,
    getAssetsCalledForIssuer,
    issuerShortName,
    setOperationLoading,
  ]);
};

export const useIsInitialDataLoaded = () => {
  const { displayRenderBlockingLoadingScreen } =
    useContext(GlobalLoadingContext);
  const { getAssetsLoading, initialPageLoadHandlerCalled } =
    useSelector(issuerSelector);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { verifyingCookie } = useSelector(authSelector);
  const flagsLoading = useAreFeatureFlagsLoading();
  const { loading: halfMagicStatusLoading } = useIsHalfMagicLoggedIn();

  return (
    initialPageLoadHandlerCalled &&
    !getAssetsLoading &&
    !verifyingCookie &&
    !flagsLoading &&
    !halfMagicStatusLoading &&
    !displayRenderBlockingLoadingScreen
  );
};
