import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import type { IRouterParams } from '../../app/router-utils';
import { store } from '../../app/store';
import { userSelector, issuerSelector } from '../../selectors';

/** Tries every possible way to get the issuer shortname */
const useGetIssuerShortname = (): string | undefined => {
  // The below is basically equivalent to:
  // const { companyShortName: shortnameFromUiSelector } = useSelector(issuerSelector);
  // But this hook may be called from outside of the Redux Provider, which causes
  // an error. So we do it this way instead, to make it safe to try accessing the
  // Redux store without erroring if it's not available.
  // https://stackoverflow.com/a/78182373/5602521
  const [shortnameFromUiSelector, setShortnameFromUiSelector] = useState<
  string | undefined
  >(undefined);

  const [shortnameFromUserSelector, setShortnameFromUserSelector] = useState<
  string | undefined
  >(undefined);

  useEffect(() => {
    try {
      const unsubscribe = store.subscribe(() => {
        const { companyShortName } = issuerSelector(store.getState());
        if (companyShortName !== shortnameFromUiSelector) {
          setShortnameFromUiSelector(companyShortName);
        }

        const { company } = userSelector(store.getState());
        if (company?.shortname !== shortnameFromUserSelector) {
          setShortnameFromUserSelector(company?.shortname);
        }
      });

      return unsubscribe;
    } catch (error) {
      // An error is expected; it will happen when this hook is called from outside of
      // the Redux Provider. So don't bother logging it.
    }
    // Return a do-nothing function to keep the type signature consistent (since we return a cleanup
    // function inside the 'try' block above):
    return () => {};
  }, [shortnameFromUiSelector, shortnameFromUserSelector]);

  // We have to use useRouteMatch instead of useParams because useGetIssuerShortname may be called
  // in a component that is not a child of a <Route>, in which case useParams whon't work.
  // https://github.com/remix-run/react-router/issues/7026#issuecomment-552582981
  const match = useRouteMatch<IRouterParams>('/:ticker');
  const shortnameFromRouterParams = match?.params.ticker;

  // Prefer the company in the user selector:
  if (
    shortnameFromUserSelector &&
    typeof shortnameFromUserSelector === 'string'
  ) {
    return shortnameFromUserSelector.toLowerCase();
  }

  // If the user selector issuer isn't set, try ui selector:
  if (shortnameFromUiSelector && typeof shortnameFromUiSelector === 'string') {
    return shortnameFromUiSelector.toLowerCase();
  }

  // Otherwise, try pulling it from the URL from useParams:
  return shortnameFromRouterParams
    ? shortnameFromRouterParams.toLowerCase()
    : shortnameFromRouterParams;
};

export default useGetIssuerShortname;
