import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { issuerSelector } from '../../../../selectors';
import { useLocale } from '../../../../locale/use-locale';

export const useSetPageTitle = () => {
  const { displayName, productNameShortByLang, productName } =
    useSelector(issuerSelector);
  const { locale } = useLocale();

  const setPageTitle = useCallback(
    (title?: string) => {
      document.title =
        title?.trim() ??
        (displayName
          ? `${displayName} - ${
            (productNameShortByLang[locale] ?? productName) || 'ESPP'
          }`
          : 'ESPP.com');
    },
    [displayName, locale, productName, productNameShortByLang],
  );

  return {
    setPageTitle,
  };
};
