import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from '@sentry/react';
import {
  CaptureConsole as CaptureConsoleIntegration,
  ExtraErrorData as ExtraErrorDataIntegration,
  ReportingObserver as ReportingObserverIntegration,
  SessionTiming as SessionTimingIntegration,
} from '@sentry/integrations';
import { match } from 'ts-pattern';
import App from './app';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { initializeLogrocket } from './logRocket';
import { IGNORED_SENTRY_ERRORS } from './utils/ignored-sentry-errors';
import { config } from './utils/config';

const {
  isProd, isLocalDev, environmentName, amplitudeUrl, useLogrocket,
} =
  config;

if (useLogrocket) {
  initializeLogrocket();
}

if (amplitudeUrl) {
  if (isProd) {
    const sessionReplayTracking = sessionReplayPlugin({ sampleRate: 1 });
    amplitude.add(sessionReplayTracking);
  }

  amplitude.init(amplitudeUrl, undefined, {
    defaultTracking: true,
  });
}

const tracesSampleRate = match({ isProd, isLocalDev })
  .with({ isProd: true }, () => 0.01)
  .with({ isLocalDev: true }, () => 0)
  .otherwise(() => 1);

// We want to init sentry for localhost (to make the dev environment more similar to production)
// but not actually send any data to Sentry (because code under active development in localhost
// is gonna have a lot of random issues and we don't need to track them in Sentry).
Sentry.init({
  dsn: 'https://56fd57d3e4904a0081fe82111f5db5ee@o1412184.ingest.sentry.io/6751029',
  ignoreErrors: IGNORED_SENTRY_ERRORS,
  integrations: [
    new Sentry.BrowserTracing({
      // Only trace requests to urls matching this regex:
      tracePropagationTargets: [/^https:\/\/(.*\.)?espp\.com\/api.*/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
    new CaptureConsoleIntegration({
      levels: ['warn', 'error', 'debug', 'assert'],
    }),
    new ExtraErrorDataIntegration(),
    new ReportingObserverIntegration({
      types: ['crash'],
    }),
    new SessionTimingIntegration(),
  ],
  environment: environmentName,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate,
  // Do not log events or errors for local dev:
  sampleRate: isLocalDev ? 0 : 1,

  // Session Replay
  // Only capture replays for when errors occurred:
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: isLocalDev ? 0 : 1.0,
});

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
