import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { match, P } from 'ts-pattern';
import { Interval } from 'luxon';
import { useIsEsppOnly } from '../use-is-espp-only';
import { useIsCashlessMatchPlan } from '../useIsCashlessMatchPlan';
import { issuerSelector } from '../../selectors';
import type { IEnrollmentPeriodInfo } from '../../selectors/interfaces';
import {
  useEnrollmentPeriodDates,
  useGetAllEnrollmentPeriods,
} from '../use-get-periods-info/use-enrollment-period';
import { useIssuerIsEsppOnly } from '../use-is-espp-only/use-plan-is-espp-only';

export enum EnrollmentPlanEspp {
  INTERNAL_ESPP = 'INTERNAL_ESPP',

  EXTERNAL_ESPP = 'EXTERNAL_ESPP',
}

export enum EnrollmentPlanCashless {
  INTERNAL_CASHLESS_PARTICIPATION = 'INTERNAL_CASHLESS_PARTICIPATION',

  INTERNAL_CASHLESS_MATCH = 'INTERNAL_CASHLESS_MATCH',

  NONE = 'NONE',
}

export enum EnrollmentPeriodsConcurrency {
  /**
   * The periods of ESPP and Cashless are the same.
   * We know the user's status.
   */
  FULLY_CONCURRENT_INTERNAL = 'FULLY_CONCURRENT_INTERNAL',

  /**
   * The periods of ESPP and Cashless may be the same or may be different.
   * We know the user's status.
   */
  PARTIALLY_CONCURRENT_INTERNAL = 'PARTIALLY_CONCURRENT_INTERNAL',

  /**
   * The periods of ESPP and Cashless overlap.
   * We may not know the user's status for the previous period before proceeding to the next one.
   */
  CONCURRENT_EXTERNAL = 'CONCURRENT_EXTERNAL',

  /**
   * The periods of ESPP and Cashless do not overlap.
   * We know the user's status for the previous period before proceeding to the next one.
   */
  NON_CONCURRENT = 'NON_CONCURRENT',
}

export interface IEnrollmentPlan {
  espp: EnrollmentPlanEspp;
  cashless: EnrollmentPlanCashless;
  concurrency: EnrollmentPeriodsConcurrency;
  /** This is not the same as cashless !== EnrollmentPlanCashless.NONE
   * as it requires all jurisdictions to be ESPP only.
   * It should generally only be used on admin pages. */
  isEsppOnlyIssuer: boolean;
}

const useGetEnrollmentPlanEspp = (
  allEnrollmentPeriods: (IEnrollmentPeriodInfo | null)[],
) => {
  const { cashlessOnlyEnrollment } = useSelector(issuerSelector);

  const espp = useMemo(
    () =>
      (allEnrollmentPeriods.find(
        (period) =>
          period !== null &&
          period.traditionalEnrollment &&
          period.traditionalEnrollmentIsExternal,
      ) ?? cashlessOnlyEnrollment
        ? EnrollmentPlanEspp.EXTERNAL_ESPP
        : EnrollmentPlanEspp.INTERNAL_ESPP),
    [allEnrollmentPeriods, cashlessOnlyEnrollment],
  );

  return espp;
};

const useGetEnrollmentPlanCashless = () => {
  const isEsppOnly = useIsEsppOnly();
  const isCashlessMatch = useIsCashlessMatchPlan();

  const cashless = useMemo(
    () =>
      match({
        isEsppOnly,
        isCashlessMatch,
      })
        .with({ isEsppOnly: true }, () => EnrollmentPlanCashless.NONE)
        .with(
          { isCashlessMatch: true },
          () => EnrollmentPlanCashless.INTERNAL_CASHLESS_MATCH,
        )
        .otherwise(
          () => EnrollmentPlanCashless.INTERNAL_CASHLESS_PARTICIPATION,
        ),
    [isCashlessMatch, isEsppOnly],
  );

  return cashless;
};

const useGetEnrollmentPeriodsConcurrency = (
  espp: EnrollmentPlanEspp,
  cashless: EnrollmentPlanCashless,
): EnrollmentPeriodsConcurrency => {
  const { cashlessOnlyEnrollment, cashlessOnlyEnrollmentIsConcurrent } =
    useSelector(issuerSelector);
  const {
    closestEnrollmentDates,
    closestEsppEnrollmentDates,
    closestCashlessEnrollmentDates,
    futureEsppEnrollmentDates,
    futureCashlessEnrollmentDates,
  } = useEnrollmentPeriodDates();

  const esppAndCashlessDatesOverlapped = useMemo(
    () =>
      closestEnrollmentDates.isDatesOverlappingWithNextPeriod ||
      (!closestEsppEnrollmentDates && !closestCashlessEnrollmentDates) ||
      (!!closestEsppEnrollmentDates &&
        !!closestCashlessEnrollmentDates &&
        Interval.fromDateTimes(
          closestEsppEnrollmentDates.start,
          closestEsppEnrollmentDates.end,
        ).overlaps(
          Interval.fromDateTimes(
            closestCashlessEnrollmentDates.start,
            closestCashlessEnrollmentDates.end,
          ),
        )),
    [
      closestCashlessEnrollmentDates,
      closestEnrollmentDates.isDatesOverlappingWithNextPeriod,
      closestEsppEnrollmentDates,
    ],
  );

  const esppAndCashlessDatesTheSame = useMemo(
    () =>
      esppAndCashlessDatesOverlapped &&
      !!closestEsppEnrollmentDates &&
      !!closestCashlessEnrollmentDates &&
      Interval.fromDateTimes(
        closestEsppEnrollmentDates.start,
        closestEsppEnrollmentDates.end,
      ).equals(
        Interval.fromDateTimes(
          closestCashlessEnrollmentDates.start,
          closestCashlessEnrollmentDates.end,
        ),
      ) &&
      ((!futureCashlessEnrollmentDates && !futureEsppEnrollmentDates) ||
        (!!futureCashlessEnrollmentDates &&
          !!futureEsppEnrollmentDates &&
          Interval.fromDateTimes(
            futureEsppEnrollmentDates.start,
            futureEsppEnrollmentDates.end,
          ).equals(
            Interval.fromDateTimes(
              futureCashlessEnrollmentDates.start,
              futureCashlessEnrollmentDates.end,
            ),
          ))),
    [
      esppAndCashlessDatesOverlapped,
      closestCashlessEnrollmentDates,
      closestEsppEnrollmentDates,
      futureCashlessEnrollmentDates,
      futureEsppEnrollmentDates,
    ],
  );

  const concurrency = useMemo(
    () =>
      match({
        espp,
        cashless,
        isConcurrentCashlessOnlyEnrollment:
          !!cashlessOnlyEnrollment && !!cashlessOnlyEnrollmentIsConcurrent,
        esppAndCashlessDatesOverlapped,
        esppAndCashlessDatesTheSame,
      })
        .with(
          {
            cashless: EnrollmentPlanCashless.NONE,
          },
          {
            espp: EnrollmentPlanEspp.INTERNAL_ESPP,
            cashless: EnrollmentPlanCashless.INTERNAL_CASHLESS_MATCH,
          },
          {
            espp: EnrollmentPlanEspp.INTERNAL_ESPP,
            cashless: EnrollmentPlanCashless.INTERNAL_CASHLESS_PARTICIPATION,
            esppAndCashlessDatesTheSame: true,
          },
          () => EnrollmentPeriodsConcurrency.FULLY_CONCURRENT_INTERNAL,
        )
        .with(
          {
            espp: EnrollmentPlanEspp.INTERNAL_ESPP,
            cashless: EnrollmentPlanCashless.INTERNAL_CASHLESS_PARTICIPATION,
            esppAndCashlessDatesOverlapped: true,
            esppAndCashlessDatesTheSame: false,
          },
          () => EnrollmentPeriodsConcurrency.PARTIALLY_CONCURRENT_INTERNAL,
        )
        .with(
          {
            espp: EnrollmentPlanEspp.EXTERNAL_ESPP,
            cashless: EnrollmentPlanCashless.INTERNAL_CASHLESS_PARTICIPATION,
            isConcurrentCashlessOnlyEnrollment: true,
          },
          {
            espp: EnrollmentPlanEspp.EXTERNAL_ESPP,
            cashless: EnrollmentPlanCashless.INTERNAL_CASHLESS_PARTICIPATION,
            esppAndCashlessDatesOverlapped: true,
          },
          () => EnrollmentPeriodsConcurrency.CONCURRENT_EXTERNAL,
        )
        .with(
          {
            espp: EnrollmentPlanEspp.EXTERNAL_ESPP,
            cashless: EnrollmentPlanCashless.INTERNAL_CASHLESS_PARTICIPATION,
            isConcurrentCashlessOnlyEnrollment: false,
          },
          {
            espp: P.union(
              EnrollmentPlanEspp.INTERNAL_ESPP,
              EnrollmentPlanEspp.EXTERNAL_ESPP,
            ),
            cashless: EnrollmentPlanCashless.INTERNAL_CASHLESS_PARTICIPATION,
            esppAndCashlessDatesOverlapped: false,
          },
          () => EnrollmentPeriodsConcurrency.NON_CONCURRENT,
        )
        .otherwise(() => undefined),
    [
      espp,
      cashless,
      cashlessOnlyEnrollment,
      cashlessOnlyEnrollmentIsConcurrent,
      esppAndCashlessDatesOverlapped,
      esppAndCashlessDatesTheSame,
    ],
  );

  if (concurrency === undefined) {
    console.error(
      `Unsupported enrollment periods combination: espp: ${espp}, cashless: ${cashless}`,
    );
  }

  return concurrency ?? EnrollmentPeriodsConcurrency.FULLY_CONCURRENT_INTERNAL;
};

/** This hook is used to obtain basic information about the user's or issuer's enrollment plan
 * (depending on whether the user is authenticated or not) */
export const useGetEnrollmentPlan = (): IEnrollmentPlan => {
  const { allPeriods: allEnrollmentPeriods } = useGetAllEnrollmentPeriods();

  const espp = useGetEnrollmentPlanEspp(allEnrollmentPeriods);
  const cashless = useGetEnrollmentPlanCashless();
  const concurrency = useGetEnrollmentPeriodsConcurrency(espp, cashless);
  const isEsppOnlyIssuer = useIssuerIsEsppOnly();

  return useMemo(
    () => ({
      espp,
      cashless,
      concurrency,
      isEsppOnlyIssuer,
    }),
    [espp, cashless, concurrency, isEsppOnlyIssuer],
  );
};

export default useGetEnrollmentPlan;
