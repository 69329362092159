/** @jsxImportSource @emotion/react */
// see - https://www.figma.com/file/pv2HV6DTSKY612VWeYWYdL/CE22-Design-System?node-id=3%3A4
import type { PropsWithChildren } from 'react';
import React, { useRef } from 'react';
import type { Interpolation, Theme } from '@emotion/react';
import type { AriaButtonProps } from 'react-aria';
import { useButton } from 'react-aria';
import type { IPropsWithTestId } from '../../../constants/data-element-tracking-ids';
import { ELEMENT_ID_DATA_ATTRIBUTE } from '../../../constants/data-element-tracking-ids';

interface IProps extends IPropsWithTestId {
  type: NonNullable<React.ButtonHTMLAttributes<HTMLButtonElement>['type']>;
  additionalStyles?: Interpolation<Theme>;
}

/** Use onPress, not onClick */
const UnstyledButton: React.FC<
PropsWithChildren<IProps & Omit<AriaButtonProps<'button'>, 'onClick'>>
> = ({
  children,
  type,
  additionalStyles = {},
  dataElementId,
  isDisabled,
  ...ariaButtonProps
}) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton({ ...ariaButtonProps, isDisabled }, ref);

  const unstyledButtonStyle: Interpolation<Theme> = {
    all: 'unset',
    // outline: revert is required to make tabindex focus still visible.
    outline: 'revert',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
  };

  return (
    <button
      css={[unstyledButtonStyle, additionalStyles]}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonProps}
      // eslint-disable-next-line react/button-has-type
      type={type}
      ref={ref}
      // JSX hack to specify a data-attribute whose name is
      // in a variable:
      {...{ [ELEMENT_ID_DATA_ATTRIBUTE]: dataElementId }}
    >
      {children}
    </button>
  );
};

export default UnstyledButton;
