import type { IPageTitleData } from '../dotcms/dotcms-models/dotcms-page-title-list';
import { Page } from './page-url-path';

export const PageTitleKeyForPage: Readonly<{
  [key in Page]: keyof IPageTitleData;
}> = {
  [Page.ABOUT_CASHLESS]: 'titleCashlessParticipationPage',
  [Page.ADMIN_DATA_CONTRIBUTIONS]: 'titleAdminContributionsPage',
  [Page.ADMIN_DATA_EXCHANGE]: 'titleAdminDataExchangePage',
  [Page.ADMIN_DATA_UPLOAD]: 'titleAdminDataUploadPage',
  [Page.ADMIN_EMPLOYEE_LOOKUP]: 'titleAdminEmployeeLookupPage',
  [Page.ADMIN_ENROLLMENT_STATS]: 'titleAdminEnrollmentStatsPage',
  [Page.ADMIN_EXCHANGE_RATES]: 'titleAdminExchangeRatesPage',
  [Page.ADMIN_PLAN_OVERVIEW]: 'titleAdminPlanOverviewPage',
  [Page.ADMIN_PURCHASE_LOOKUP]: 'titleAdminPurchaseLookup',
  // Admin purchase tabs have same title as overall purchase page for now:
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_HIGHLIGHTS]: 'titleAdminPurchaseLookup',
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_LOOKUP]: 'titleAdminPurchaseLookup',
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_OVERVIEW]: 'titleAdminPurchaseLookup',
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION]: 'titleAdminConfiguration',
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION_TAB_PLAN]:
    'titleAdminConfiguration',
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION_TAB_ENROLLMENT]:
    'titleAdminConfiguration',
  [Page.ADMIN_STOCK_BASED_COMPENSATION]: 'titleAdminStockBasedCompensationPage',
  [Page.ADMIN_SURVEY_RESULTS]: 'titleAdminSurveyResultsPage',
  [Page.ADMIN_PAGE]: 'titleDefault',
  [Page.ANTI_BOT_MAGIC_LINK_ENROLLMENT_WELCOME]: 'titleDefault',
  [Page.ANTI_BOT_BROKER_QUESTIONS_REDIRECT]: 'titleDefault',
  [Page.ACCOUNT_PAGE]: 'titleDefault',
  [Page.ACCOUNT_PAGE_CONTACT_INFORMATION]: 'titleContactInformationPage',
  [Page.ACCOUNT_PAGE_DOCUMENTS]: 'titleDocumentsPage',
  [Page.ACCOUNT_PAGE_MANAGE_ELECTION]: 'titleManageElectionPage',
  [Page.ACCOUNT_PAGE_PURCHASE_DETAILS]: 'titlePurchaseSummaryPage',
  [Page.ACCOUNT_PAGE_STATUS_SUMMARY]: 'titleAccountStatusPage',
  [Page.ACCOUNT_PAGE_STOCK_INFORMATION]: 'titleStockInformationPage',
  [Page.AUTO_SALE_BROKER_QUESTIONS]: 'titleAutoSaleBrokerQuestionsPage',
  [Page.CALCULATOR]: 'titleEsppCalculatorPage',
  [Page.CE_INTERNAL_DYNAMIC_VARIABLE_VIEWER]: 'titleDefault',
  [Page.DEFAULT]: 'titleDefault',
  [Page.EMAIL_VERIFICATION_COMPLETE]: 'titleEmailVerificationCompletePage',
  [Page.ENROLLMENT_PAGE]: 'titleEnrollmentPage',
  [Page.HOMEPAGE]: 'titleHomepage',
  [Page.IMPORTANT_INFORMATION]: 'titleImportantInformationPage',
  [Page.LOGIN_PAGE]: 'titleLoginPage',
  [Page.PRIVACY_POLICY]: 'titlePrivacyPage',
  [Page.REFERRAL]: 'titleReferralPage',
  [Page.RESOURCE_LIBRARY]: 'titleEsppResourcesPage',
  [Page.RESOURCE_LIBRARY_ID]: 'titleSuffixArticlesPages',
  [Page.STANDALONE_BROKER_QUESTIONS]: 'titleDefault',
  [Page.TAX_INFORMATION]: 'titleTaxInformationPage',
  [Page.TEMP_PDF_VIEWER_TEST]: 'titleDefault',
  [Page.TERM_OF_SERVICE]: 'titleTermsOfServicePage',
  [Page.UNSUBSCRIBE]: 'titleUnsubscribePage',
  [Page.WEALTH_MANAGEMENT]: 'titleWealthManagementPage',
};
