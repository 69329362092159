import type { ReactNode } from 'react';
import React from 'react';
import { match, P } from 'ts-pattern';
import {
  AnchorButton, Button, ButtonVariant, LinkButton,
} from '../Button';
import { useRedirectToExternalUrl } from '../../../hooks/useRedirectToExternalUrl';
import type { IPropsWithTestId } from '../../../constants/data-element-tracking-ids';
import type { ITheme, ThemeColor } from '../../styles/theme';
import type { ButtonSize, IButtonProps } from '../Button/button-style-helper';

export interface IDialogButton extends IPropsWithTestId {
  content?: ReactNode;
  onClick?: () => void;
  route?: string;
  isDisabled?: boolean;
  variant?: ButtonVariant;
  color?: ThemeColor;
}

export interface IDialogButtonProps {
  button?: IDialogButton;
  isPrimaryButton?: boolean;
  theme: ITheme;
}

export const DialogButton: React.FC<IDialogButtonProps> = ({
  button,
  isPrimaryButton = false,
  theme,
}) => {
  const { checkIfExternalUrl } = useRedirectToExternalUrl();

  if (!button) {
    return null;
  }

  const buttonAttributes: IPropsWithTestId &
  Pick<IButtonProps, 'variant' | 'theme' | 'color' | 'type' | 'size'> & {
    isDisabled: boolean;
  } = {
    dataElementId: button.dataElementId,
    isDisabled: !!button.isDisabled,
    variant:
      button.variant ??
      (isPrimaryButton ? ButtonVariant.PRIMARY : ButtonVariant.TERTIARY),
    theme,
    color: button.color,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    type: 'button' as NonNullable<
    React.ButtonHTMLAttributes<HTMLButtonElement>['type']
    >,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    size: 'default' as ButtonSize,
  };

  return match({
    route: button.route,
    isExternalUrl: button.route ? checkIfExternalUrl(button.route) : false,
  })
    .with(
      {
        isExternalUrl: true,
      },
      () => (
        <AnchorButton
          href={button.route ?? '/'}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...buttonAttributes}
          onClick={button.onClick}
        >
          {button.content}
        </AnchorButton>
      ),
    )
    .with(
      {
        route: P.not(undefined),
      },
      () => (
        <LinkButton
          to={button.route ?? '/'}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...buttonAttributes}
          onClick={button.onClick}
        >
          {button.content}
        </LinkButton>
      ),
    )
    .otherwise(() => (
      <Button
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...buttonAttributes}
        onPress={button.onClick}
      >
        {button.content}
      </Button>
    ));
};
