import React from 'react';
import BasicPopup from '../basic-popup';
import { DataElementId } from '../../../../constants/data-element-tracking-ids';
import useAutomaticSignoutPopup from './hooks/use-automatic-signout-popup';

const AutomaticSignoutPopup = () => {
  const {
    loginUrl,
    handleDialogClose,
    dialogData,
    cmsLoading,
    cmsError,
    dialogOpen,
  } = useAutomaticSignoutPopup();

  return (
    <BasicPopup
      id="automatic-signout-popup"
      dataElementId={DataElementId.AUTOMATIC_SIGNOUT_POPUP}
      dialogData={dialogData}
      dialogOpen={dialogOpen}
      loading={cmsLoading}
      cmsError={cmsError}
      handleOnClose={handleDialogClose}
      primaryButton={{
        content: dialogData?.primaryButton?.text,
        route: loginUrl,
        onClick: handleDialogClose,
        dataElementId: DataElementId.AUTOMATIC_SIGNOUT_POPUP_SIGN_IN_BUTTON,
      }}
    />
  );
};

export default AutomaticSignoutPopup;
