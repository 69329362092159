import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useGetAllEnrollmentPeriods } from '../use-get-all-periods';
import { authSelector, issuerSelector, userSelector } from '../../../selectors';
import { PotentialOfferingPeriodAction } from '../../../selectors/interfaces';

export interface IEnrollmentPeriodOpenInfo {
  isEnrollmentOpen: boolean;
  isEsppEnrollmentOpen: boolean;
  isCashlessEnrollmentOpen: boolean;

  isEnrollmentOpenForEsppOnly: boolean;
  isEnrollmentOpenForCashlessOnly: boolean;

  isExternalEnrollmentOpen: boolean;
  isEsppInternalEnrollmentOpen: boolean;
  isEsppExternalEnrollmentOpen: boolean;

  //* *Otherwise, it is open for the entire offering period. */
  isEnrollmentOpenForPurchasePeriod: boolean;

  isFirstEnrollmentForOfferingOpen: boolean;

  isQuietPeriodBetweenEsppAndCashless: boolean;

  isOpenEnrollmentConnectedToNext: boolean;

  isScheduledEnrollmentOpen: boolean;
  isEnrollmentOrScheduledEnrollmentOpen: boolean;
}

export const useEnrollmentPeriodOpenInfo = (): IEnrollmentPeriodOpenInfo => {
  const { currentPeriod, futurePeriod } = useGetAllEnrollmentPeriods();
  const { isAuthenticated } = useSelector(authSelector);
  const { isScheduledEnrollmentAllowed } = useSelector(issuerSelector);
  const { futureOpAllowedActions } = useSelector(userSelector);

  return useMemo(() => {
    const isEnrollmentOpen = !!currentPeriod;

    const isEsppEnrollmentOpen =
      !!currentPeriod && currentPeriod.traditionalEnrollment;
    const isEsppExternalEnrollmentOpen =
      isEsppEnrollmentOpen && currentPeriod.traditionalEnrollmentIsExternal;
    const isEsppInternalEnrollmentOpen =
      isEsppEnrollmentOpen && !currentPeriod.traditionalEnrollmentIsExternal;

    const isCashlessEnrollmentOpen =
      !!currentPeriod && currentPeriod.cashlessEnrollment;
    // We don't currently support cashless external enrollment functionality
    const isCashlessExternalEnrollmentOpen = false;

    const isEnrollmentOpenForEsppOnly =
      isEsppInternalEnrollmentOpen && !isCashlessEnrollmentOpen;
    const isEnrollmentOpenForCashlessOnly =
      !isEsppInternalEnrollmentOpen && isCashlessEnrollmentOpen;

    const isExternalEnrollmentOpen =
      isEsppExternalEnrollmentOpen || isCashlessExternalEnrollmentOpen;

    const isEnrollmentOpenForPurchasePeriod =
      !!currentPeriod && currentPeriod.purchasePeriodEnrollment;

    const isFirstEnrollmentForOfferingOpen =
      !!currentPeriod && currentPeriod.firstEnrollmentForOffering;

    const isQuietPeriodBetweenEsppAndCashless =
      !isEnrollmentOpen &&
      !futurePeriod.traditionalEnrollment &&
      futurePeriod.cashlessEnrollment;

    const isOpenEnrollmentConnectedToNext =
      isEnrollmentOpen && !!currentPeriod?.nextEnrollmentPeriodIsConnected;

    const isScheduledEnrollmentOpen =
      !isEnrollmentOpen &&
      (isAuthenticated && futureOpAllowedActions
        ? !!futureOpAllowedActions?.includes(
          PotentialOfferingPeriodAction.ScheduledEnrollmentAction,
        )
        : !!isScheduledEnrollmentAllowed);
    const isEnrollmentOrScheduledEnrollmentOpen =
      isEnrollmentOpen || isScheduledEnrollmentOpen;

    return {
      isEnrollmentOpen,
      isEsppEnrollmentOpen,
      isCashlessEnrollmentOpen,

      isEnrollmentOpenForEsppOnly,
      isEnrollmentOpenForCashlessOnly,

      isExternalEnrollmentOpen,
      isEsppInternalEnrollmentOpen,
      isEsppExternalEnrollmentOpen,

      isEnrollmentOpenForPurchasePeriod,
      isFirstEnrollmentForOfferingOpen,
      isQuietPeriodBetweenEsppAndCashless,
      isOpenEnrollmentConnectedToNext,

      isScheduledEnrollmentOpen,
      isEnrollmentOrScheduledEnrollmentOpen,
    };
  }, [
    currentPeriod,
    futurePeriod,
    futureOpAllowedActions,
    isScheduledEnrollmentAllowed,
    isAuthenticated,
  ]);
};
