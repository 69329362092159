import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { LocaleContext } from '../locale-context/locale-context';
import { useGetUserLocation } from '../../hooks/use-get-user-location/use-get-user-location';
import { issuerSelector } from '../../selectors';

interface UseLocaleResult {
  locale: string;
  setLocale: (language: string) => void;
  availableLanguages: ReadonlyArray<{
    languageCode: string;
    languageName: string;
  }>;
  /**
    We use userPickedLanguage to know whether to overwrite the user's existing
    language preference when logging in.
    E.g. if the user selects Spanish while unauthenticated, and then logs in,
    we want to tell the backend to save their language as Spanish. But if the
    user logs in without switching away from the default language, we don't
    want to overwrite their language on the backend with the default one that
    they didn't pick.
   */
  userHasPickedLanguage: boolean;
}

const useLocale = (): UseLocaleResult => {
  const { locale, setLocale, userHasPickedLanguage } =
    useContext(LocaleContext);
  const { jurisdictionByCountryCode, languageCodeToLanguageInfo } =
    useSelector(issuerSelector);

  const { countryCode } = useGetUserLocation();

  const jurisdictionInfo = jurisdictionByCountryCode?.[countryCode];

  const availableLanguagesRaw = useMemo(
    () => jurisdictionInfo?.supported_languages ?? [],
    [jurisdictionInfo?.supported_languages],
  );

  const availableLanguages: ReadonlyArray<{
    languageCode: string;
    languageName: string;
  }> = useMemo(
    () =>
      availableLanguagesRaw
        .map((languageCode) => {
          const infoForThisLanguage =
            languageCodeToLanguageInfo?.[languageCode];
          return {
            languageCode,
            languageName: infoForThisLanguage?.display_name ?? '',
            cmsId: infoForThisLanguage?.cms_id,
          };
        })
        .filter(
          (languageInfo) =>
            // Only display a language as an option if we know its display name
            // and cms ID:
            !!languageInfo?.languageName && !!languageInfo.cmsId,
        ),
    [availableLanguagesRaw, languageCodeToLanguageInfo],
  );

  return {
    locale,
    setLocale,
    userHasPickedLanguage,
    availableLanguages,
  };
};

export default useLocale;
