import { useSelector } from 'react-redux';
import { useGetUserLocation } from './use-get-user-location/use-get-user-location';
import { issuerSelector, userSelector } from '../selectors';
import { GDPR_COUNTRIES } from '../utils/constants';

export const useIsGdpr = (): boolean => {
  const { countryCode } = useGetUserLocation();
  const { ipAddressInGdprCountry } = useSelector(issuerSelector);
  const { personAssociatedWithGdprCountry } = useSelector(userSelector);

  // Still have to manually check agains the country code list because the country
  // might have been set from a URL parameter, which the backend cannot see when setting
  // ip_address_in_gdpr_country and person_associated_with_gdpr_country.
  return (
    ipAddressInGdprCountry ||
    personAssociatedWithGdprCountry ||
    GDPR_COUNTRIES.includes(countryCode)
  );
};
