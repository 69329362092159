/** @jsxImportSource @emotion/react */
// see - https://www.figma.com/file/pv2HV6DTSKY612VWeYWYdL/CE22-Design-System?node-id=3%3A10
import type { AnchorHTMLAttributes, PropsWithChildren } from 'react';
import React from 'react';
import type { Interpolation, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import type { Theme as MuiTheme } from '@mui/material';
import type { ITheme, ThemeColor } from '../../styles/theme';
import { theme as defaultTheme } from '../../styles/theme';
import Typography, { TypographyVariant } from '../Typography/Typography';
import type { IPropsWithTestId } from '../../../constants/data-element-tracking-ids';
import { ELEMENT_ID_DATA_ATTRIBUTE } from '../../../constants/data-element-tracking-ids';
import type { SettingBasedOnBreakpoints } from '../../../hooks/use-setting-based-on-breakpoints';

const StyledLink = styled.a`
  text-decoration: none;
`;

interface IProps extends IPropsWithTestId {
  href: NonNullable<AnchorHTMLAttributes<unknown>['href']>;
  variant?: TypographyVariant | SettingBasedOnBreakpoints<TypographyVariant>;
  inlineLink?: boolean;
  theme?: ITheme;
  muiTheme?: MuiTheme;
  color?: ThemeColor;
  additionalStyles?: Interpolation<Theme>;
  target?: AnchorHTMLAttributes<unknown>['target'];
}

/** Only use this for links to external sites! Otherwise use Link.tsx */
const AnchorLink: React.FC<
PropsWithChildren<
IProps &
Omit<
AnchorHTMLAttributes<unknown>,
'style' | 'children' | 'color' | 'href' | 'target'
>
>
> = ({
  href,
  variant = TypographyVariant.LINKS_MEDIUM,
  inlineLink = false,
  children,
  theme = defaultTheme,
  muiTheme,
  color,
  additionalStyles = {},
  dataElementId,
  target,
  ...props
}) => {
  const linkStyles = {
    textDecorationColor: color,
  };

  const textStyles = {
    display: inlineLink ? 'contents' : '',
  };

  // Target is always "_blank" because we should only be using this AnchorLink
  // to link to external domains, and it's best practice to make target="_blank"
  // when linking to an external site.
  // (Links within our own domain should use the Link component instead)
  return (
    <StyledLink
      href={href}
      css={[additionalStyles, linkStyles]}
      target={target ?? '_blank'}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...{ [ELEMENT_ID_DATA_ATTRIBUTE]: dataElementId }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Typography
        color={color}
        variant={variant}
        muiTheme={muiTheme}
        theme={theme}
        additionalStyles={textStyles}
      >
        {children}
      </Typography>
    </StyledLink>
  );
};

export default AnchorLink;
